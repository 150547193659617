import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { createDraftProposal, getProposalById, updateProposal, getProposalTypes } from '../services/apiService';
import { ProposalType } from '../types';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const SubmitProposal: React.FC = () => {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [proposalId, setProposalId] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [proposalTypes, setProposalTypes] = useState<ProposalType[]>([]);

  useEffect(() => {
    const fetchProposalTypes = async () => {
      try {
        const types = await getProposalTypes();
        setProposalTypes(types);
      } catch (error) {
        console.error('Error fetching proposal types:', error);
      }
    };

    fetchProposalTypes();
  }, []);

  const handleGenerateProposal = async () => {
    try {
      const response = await createDraftProposal();
      const proposalId = response?.id;
      const walletAddress = response.proposal_wallets_proposals_walletToproposal_wallets?.address || '';
      
      if (!proposalId) {
        throw new Error('No proposal ID received from server');
      }
      
      setProposalId(proposalId);
      setWalletAddress(walletAddress);

      const proposal = await getProposalById(proposalId);
      setTitle(proposal.title || '');
      setDescription(proposal.description || '');
      setBody(proposal.body || '');
      setType('DRAFT');
      setIsGenerated(true);

      await updateProposal(proposalId, { 
        status: 1,
        title: proposal.title,
        description: proposal.description 
      });
    } catch (error) {
      console.error('Error generating proposal:', error);
      alert('Failed to generate proposal.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !description || !body || type === 'DRAFT') {
      alert('All fields are required and type cannot be "DRAFT".');
      return;
    }

    const proposalData = {
      title,
      description,
      body,
      type: proposalTypes.find(pt => pt.name === type)?.id || 0,
      approved: false,
      reviewed: false,
      status: 2,
      submitted: new Date().toISOString(),
    };

    try {
      setIsSubmitting(true);
      if (proposalId !== null) {
        await updateProposal(proposalId, proposalData);
        setShowSuccess(true);
        setIsGenerated(false);
      }
    } catch (error) {
      console.error('Error submitting proposal:', error);
      alert('Failed to submit proposal.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        {showSuccess ? (
          <div className="card p-4 text-center max-w-2xl mx-auto">
            <h2 className="section-title mb-4">Proposal Submitted Successfully!</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Your proposal submission will not be reviewed until it receives its first nomination. You can nominate your proposal here:{' '}
              <a 
                href={`https://gov.kaspadao.org/proposals/${proposalId}`}
                className="text-primary hover:underline"
              >
                https://gov.kaspadao.org/proposals/{proposalId}
              </a>
            </p>
          </div>
        ) : (
          <div>
            {!isGenerated ? (
              <div className="card p-4 text-center max-w-2xl mx-auto">
                <h2 className="section-title mb-4">Create New Proposal</h2>
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                  Click below to generate a new draft proposal for you to edit and submit.
                </p>
                <button onClick={handleGenerateProposal} className="button-primary">
                  Generate Proposal
                </button>
              </div>
            ) : (
              <div className="card p-4 max-w-4xl mx-auto">
                <h2 className="section-title mb-6">Submit Proposal</h2>
                <p className="text-gray-600 dark:text-gray-300 mb-6">Proposal Wallet Address: {walletAddress}</p>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="form-label">Type</label>
                    <select 
                      value={type} 
                      onChange={(e) => setType(e.target.value)} 
                      className="select-field" 
                      required
                    >
                      {proposalTypes.map((proposalType) => (
                        <option key={proposalType.id} value={proposalType.name}>
                          {proposalType.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="form-label">Title</label>
                    <input 
                      type="text" 
                      value={title} 
                      onChange={(e) => setTitle(e.target.value)} 
                      className="input-field" 
                      required 
                    />
                  </div>
                  <div>
                    <label className="form-label">Subtitle</label>
                    <input 
                      type="text" 
                      value={description} 
                      onChange={(e) => setDescription(e.target.value)} 
                      className="input-field" 
                      required 
                    />
                  </div>
                  <div>
                    <label className="form-label">Proposal Body</label>
                    <div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
                      <MarkdownEditor
                        value={body}
                        style={{ height: '400px' }}
                        renderHTML={(text) => text}
                        onChange={({ text }) => setBody(text)}
                        view={{ menu: true, md: true, html: false }}
                        config={{
                          view: {
                            menu: true,
                            md: true,
                            html: false,
                          },
                          canView: {
                            menu: true,
                            md: true,
                            html: false,
                            fullScreen: false,
                            hideMenu: false,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button type="submit" className="button-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit Proposal'}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubmitProposal;