import React, { useState } from 'react';
import { ElectionPosition } from '../../../types';
import { createElectionPosition, updateElectionPosition, deleteElectionPosition } from '../../../services/apiService';

interface NewElectionPosition {
  title: string;
  description: string;
}

interface ElectionPositionsProps {
  positions: ElectionPosition[];
  setUpdateMessage: (message: string | null) => void;
  onPositionsChange?: () => void; // Optional callback for parent to refresh data
}

export default function ElectionPositions({ 
  positions, 
  setUpdateMessage, 
  onPositionsChange 
}: ElectionPositionsProps) {
  const [editingPosition, setEditingPosition] = useState<ElectionPosition | null>(null);
  const [newPosition, setNewPosition] = useState<NewElectionPosition>({
    title: '',
    description: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      if (editingPosition) {
        // Update existing position
        await updateElectionPosition(editingPosition.id, {
          ...editingPosition,
          title: editingPosition.title.trim(),
          description: editingPosition.description.trim()
        });
        setUpdateMessage('Position updated successfully');
        setEditingPosition(null);
      } else {
        // Create new position
        await createElectionPosition({
          title: newPosition.title.trim(),
          description: newPosition.description.trim()
        });
        setUpdateMessage('Position created successfully');
        setNewPosition({ title: '', description: '' });
      }

      // Notify parent to refresh data instead of page reload
      if (onPositionsChange) {
        onPositionsChange();
      }
    } catch (error) {
      console.error('Error saving position:', error);
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An unexpected error occurred';
      setUpdateMessage(
        editingPosition 
          ? `Failed to update position: ${errorMessage}` 
          : `Failed to create position: ${errorMessage}`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (position: ElectionPosition) => {
    if (!position.id) {
      setUpdateMessage('Invalid position ID');
      return;
    }

    if (window.confirm('Are you sure you want to delete this position? This action cannot be undone.')) {
      setIsSubmitting(true);
      try {
        await deleteElectionPosition(position.id);
        setUpdateMessage('Position deleted successfully');
        
        // Notify parent to refresh data instead of page reload
        if (onPositionsChange) {
          onPositionsChange();
        }
        
        // Scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        console.error('Error deleting position:', error);
        const errorMessage = error instanceof Error 
          ? error.message 
          : 'An unexpected error occurred';
        setUpdateMessage(`Failed to delete position: ${errorMessage}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold">Manage Positions</h3>
      <div className="grid grid-cols-1 gap-4">
        {/* Add/Edit Position Form */}
        <div className="card p-4 bg-gray-50 dark:bg-gray-800">
          <h4 className="text-lg font-medium mb-4">
            {editingPosition ? 'Edit Position' : 'Add New Position'}
          </h4>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Title
              </label>
              <input
                type="text"
                id="title"
                value={editingPosition ? editingPosition.title : newPosition.title}
                onChange={(e) => editingPosition 
                  ? setEditingPosition({ ...editingPosition, title: e.target.value })
                  : setNewPosition({ ...newPosition, title: e.target.value })
                }
                className="input-field mt-1 w-full"
                disabled={isSubmitting}
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Description
              </label>
              <textarea
                id="description"
                value={editingPosition ? editingPosition.description : newPosition.description}
                onChange={(e) => editingPosition
                  ? setEditingPosition({ ...editingPosition, description: e.target.value })
                  : setNewPosition({ ...newPosition, description: e.target.value })
                }
                className="input-field mt-1 w-full h-24"
                disabled={isSubmitting}
                required
              />
            </div>
            <div className="flex justify-end space-x-2">
              {editingPosition && (
                <button
                  type="button"
                  onClick={() => setEditingPosition(null)}
                  className="button-secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              )}
              <button 
                type="submit" 
                className="button-primary"
                disabled={isSubmitting}
              >
                {isSubmitting 
                  ? (editingPosition ? 'Updating...' : 'Creating...') 
                  : (editingPosition ? 'Update Position' : 'Add Position')
                }
              </button>
            </div>
          </form>
        </div>

        {/* Existing Positions List */}
        <div className="card p-4">
          <h4 className="text-lg font-medium mb-4">Existing Positions</h4>
          <div className="space-y-4">
            {positions.length === 0 ? (
              <p className="text-gray-500 dark:text-gray-400 text-center">
                No positions created yet
              </p>
            ) : (
              positions.map(position => (
                <div key={position.id} className="flex items-start justify-between p-4 bg-white dark:bg-gray-700 rounded-lg shadow">
                  <div>
                    <h5 className="font-medium">{position.title}</h5>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{position.description}</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                      Created: {new Date(position.created).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setEditingPosition(position)}
                      className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                      disabled={isSubmitting}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(position)}
                      className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                      disabled={isSubmitting}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 