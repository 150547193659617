import React, { useEffect } from 'react';
import { createSnapshot, getProposalSnapshots } from '../../../services/apiService';
import { Proposal, ProposalSnapshot } from '../../../types';

interface ProposalSnapshotsProps {
  proposals: Proposal[];
  selectedProposalId: number | null;
  setSelectedProposalId: (id: number | null) => void;
  proposalSnapshots: ProposalSnapshot[];
  setProposalSnapshots: (snapshots: ProposalSnapshot[]) => void;
  isCreatingSnapshot: boolean;
  setIsCreatingSnapshot: (isCreating: boolean) => void;
  setUpdateMessage: (message: string | null) => void;
}

// Helper function to format total supply with 8 decimal places
const formatTotalSupply = (supply: string | number): string => {
  if (!supply) return '0';
  
  // Convert to string and remove any decimals if they exist
  const rawSupply = supply.toString().split('.')[0];
  
  // Pad with zeros if needed to ensure we have at least 8 digits
  const paddedSupply = rawSupply.padStart(9, '0');
  
  // Insert decimal point 8 places from the right
  const decimalIndex = paddedSupply.length - 8;
  const formattedSupply = `${paddedSupply.slice(0, decimalIndex)}.${paddedSupply.slice(decimalIndex)}`;
  
  // Remove trailing zeros after decimal and trailing decimal if no decimals
  const cleanNumber = parseFloat(formattedSupply);
  
  // Format with thousands separators and maintain decimal places
  return cleanNumber.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8
  });
};

// Helper function to format timestamps
const formatTimestamp = (timestamp: number | string | Date): string => {
  if (!timestamp) return 'N/A';
  
  try {
    let date: Date;
    
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp === 'string') {
      // Try parsing ISO string first
      date = new Date(timestamp);
    } else {
      // Handle Unix timestamp (in seconds or milliseconds)
      const ts = timestamp.toString().length === 10 
        ? Number(timestamp) * 1000 
        : Number(timestamp);
      date = new Date(ts);
    }

    // Check if date is valid
    if (date.toString() === 'Invalid Date') return 'Invalid timestamp';
    
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return 'Invalid timestamp';
  }
};

export default function ProposalSnapshots({ 
  proposals,
  selectedProposalId,
  setSelectedProposalId,
  proposalSnapshots,
  setProposalSnapshots,
  isCreatingSnapshot,
  setIsCreatingSnapshot,
  setUpdateMessage,
}: ProposalSnapshotsProps) {
  // Fetch all snapshots on component mount
  useEffect(() => {
    const fetchSnapshots = async () => {
      try {
        const snapshots = await getProposalSnapshots();
        if (Array.isArray(snapshots)) {
          setProposalSnapshots(snapshots);
        } else {
          console.error('Invalid snapshots response:', snapshots);
          setUpdateMessage('Error: Invalid response format from server');
        }
      } catch (error) {
        console.error('Error fetching snapshots:', error);
        setUpdateMessage('Failed to fetch snapshots');
      }
    };

    fetchSnapshots();
  }, [setProposalSnapshots, setUpdateMessage]);

  const handleCreateSnapshot = async () => {
    if (!selectedProposalId) {
      setUpdateMessage('Please select a proposal');
      return;
    }

    // Check if snapshot already exists for this proposal
    const existingSnapshot = proposalSnapshots.find(s => s.proposal_id === selectedProposalId);
    if (existingSnapshot) {
      setUpdateMessage('A snapshot already exists for this proposal');
      return;
    }

    setIsCreatingSnapshot(true);
    try {
      await createSnapshot('proposal', selectedProposalId);
      setUpdateMessage('Snapshot created successfully');
      
      // Refresh snapshots with error handling
      try {
        const snapshots = await getProposalSnapshots();
        if (Array.isArray(snapshots)) {
          setProposalSnapshots(snapshots);
        } else {
          console.error('Invalid snapshots response:', snapshots);
          setUpdateMessage('Error: Invalid response format from server');
        }
      } catch (error) {
        console.error('Error refreshing snapshots:', error);
        setUpdateMessage('Snapshot created, but failed to refresh list');
      }
    } catch (error) {
      console.error('Error creating snapshot:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      setUpdateMessage(`Failed to create snapshot: ${errorMessage}`);
    } finally {
      setIsCreatingSnapshot(false);
    }
  };

  // Filter snapshots for selected proposal
  const selectedProposalSnapshots = selectedProposalId 
    ? proposalSnapshots.filter(s => s.proposal_id === selectedProposalId)
    : [];

  return (
    <div className="space-y-6">
      {/* Proposal Selection Card */}
      <div className="card bg-white dark:bg-gray-800 p-6">
        <h3 className="text-xl font-semibold mb-4">Select Proposal</h3>
        <div className="flex flex-wrap gap-4 items-center">
          <select
            className="select-field flex-grow"
            onChange={(e) => setSelectedProposalId(Number(e.target.value))}
            value={selectedProposalId || ''}
            disabled={isCreatingSnapshot}
          >
            <option value="">Select Proposal</option>
            {proposals.map(p => (
              <option key={p.id} value={p.id}>
                {p.title}
              </option>
            ))}
          </select>
          <button
            className="button-primary whitespace-nowrap"
            onClick={handleCreateSnapshot}
            disabled={isCreatingSnapshot || !selectedProposalId || selectedProposalSnapshots.length > 0}
          >
            {isCreatingSnapshot ? 'Creating Snapshot...' : 'Create Snapshot'}
          </button>
        </div>
      </div>

      {/* Snapshots Card */}
      <div className="card bg-white dark:bg-gray-800 p-6">
        <h3 className="text-xl font-semibold mb-4">Proposal Snapshots</h3>
        <div className="space-y-4">
          {!selectedProposalId ? (
            <div className="text-center p-6 bg-gray-50 dark:bg-gray-700 rounded">
              <p className="text-gray-600 dark:text-gray-300">
                Please select a proposal from the dropdown to view its snapshots
              </p>
            </div>
          ) : selectedProposalSnapshots.length === 0 ? (
            <div className="text-center p-6 bg-gray-50 dark:bg-gray-700 rounded">
              <p className="text-gray-600 dark:text-gray-300">
                No snapshots found for selected proposal
              </p>
            </div>
          ) : (
            selectedProposalSnapshots.map(snapshot => {
              const proposal = proposals.find(p => p.id === snapshot.proposal_id);
              return (
                <div key={snapshot.id} className="p-6 bg-gray-50 dark:bg-gray-700 rounded">
                  <div className="space-y-4">
                    <div className="flex justify-between items-start">
                      <h4 className="text-lg font-medium">
                        {proposal?.title || `Proposal ID: ${snapshot.proposal_id}`}
                      </h4>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        ID: {snapshot.id}
                      </span>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="p-3 bg-white dark:bg-gray-800 rounded">
                        <span className="font-medium">Secured: </span>
                        <span className="text-gray-700 dark:text-gray-300">
                          {formatTimestamp(snapshot.generated)}
                        </span>
                      </div>
                      
                      {snapshot.data && (
                        <>
                          <div className="p-3 bg-white dark:bg-gray-800 rounded">
                            <span className="font-medium">Holders: </span>
                            <span className="text-gray-700 dark:text-gray-300">
                              {snapshot.data.holders?.length.toLocaleString()}
                            </span>
                          </div>
                          
                          <div className="p-3 bg-white dark:bg-gray-800 rounded">
                            <span className="font-medium">Total Supply: </span>
                            <span className="text-gray-700 dark:text-gray-300">
                              {formatTotalSupply(snapshot.data.summary?.totalSupply)} {process.env.REACT_APP_GOV_TOKEN_TICKER}
                            </span>
                          </div>
                          
                          <div className="p-3 bg-white dark:bg-gray-800 rounded">
                            <span className="font-medium">Generated: </span>
                            <span className="text-gray-700 dark:text-gray-300">
                              {formatTimestamp(snapshot.data.timestamp)}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
} 