import React from 'react';
import { Proposal, Status } from '../../../types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface ProposalActionsProps {
  proposals: Proposal[];
  statuses: Status[];
  selectedProposalId: number | null;
  setSelectedProposalId: (id: number | null) => void;
  selectedStatusId: number | null;
  setSelectedStatusId: (id: number | null) => void;
  openVoteDate: Date | null;
  setOpenVoteDate: (date: Date | null) => void;
  closeVoteDate: Date | null;
  setCloseVoteDate: (date: Date | null) => void;
  approveRejectChoice: boolean;
  setApproveRejectChoice: (choice: boolean) => void;
  handleApproveReject: (approved: boolean) => void;
  handleStatusUpdate: () => void;
  handleSchedule: () => void;
}

const ProposalActions: React.FC<ProposalActionsProps> = ({
  proposals,
  statuses,
  selectedProposalId,
  setSelectedProposalId,
  selectedStatusId,
  setSelectedStatusId,
  openVoteDate,
  setOpenVoteDate,
  closeVoteDate,
  setCloseVoteDate,
  approveRejectChoice,
  setApproveRejectChoice,
  handleApproveReject,
  handleStatusUpdate,
  handleSchedule,
}) => {
  const selectedProposal = proposals.find(p => p.id === selectedProposalId);

  React.useEffect(() => {
    if (selectedProposal) {
      if (selectedProposal.openvote) setOpenVoteDate(new Date(selectedProposal.openvote));
      if (selectedProposal.closevote) setCloseVoteDate(new Date(selectedProposal.closevote));
      if (selectedProposal.status) setSelectedStatusId(selectedProposal.status);
    }
  }, [selectedProposal, setOpenVoteDate, setCloseVoteDate, setSelectedStatusId]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedProposalId && selectedStatusId) {
      handleApproveReject(approveRejectChoice);
      if (openVoteDate && closeVoteDate) handleSchedule();
      handleStatusUpdate();
    }
  };

  return (
    <div className="space-y-4">
      {/* Proposal Selection */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
        <select
          value={selectedProposalId || ''}
          onChange={(e) => setSelectedProposalId(e.target.value ? Number(e.target.value) : null)}
          className="w-full bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md px-3 py-2 text-sm focus:ring-2 focus:ring-primary/20"
        >
          <option value="">Select a proposal...</option>
          {proposals.map((proposal) => (
            <option key={proposal.id} value={proposal.id}>{proposal.title}</option>
          ))}
        </select>
      </div>

      {selectedProposalId && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column - Actions */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 space-y-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Approval Toggle */}
              <div className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 rounded-md p-3">
                <span className="text-sm font-medium">Approval Status</span>
                <div className="flex space-x-3">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      checked={approveRejectChoice}
                      onChange={() => setApproveRejectChoice(true)}
                      className="form-radio text-primary h-4 w-4"
                    />
                    <span className="ml-2 text-sm">Approve</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      checked={!approveRejectChoice}
                      onChange={() => setApproveRejectChoice(false)}
                      className="form-radio text-red-500 h-4 w-4"
                    />
                    <span className="ml-2 text-sm">Reject</span>
                  </label>
                </div>
              </div>

              {/* Status Selection */}
              <div>
                <select
                  value={selectedStatusId || ''}
                  onChange={(e) => setSelectedStatusId(e.target.value ? Number(e.target.value) : null)}
                  className="w-full bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md px-3 py-2 text-sm focus:ring-2 focus:ring-primary/20"
                >
                  <option value="">Select status...</option>
                  {statuses.map((status) => (
                    <option key={status.id} value={status.id}>{status.name}</option>
                  ))}
                </select>
              </div>

              {/* Vote Schedule */}
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <DatePicker
                    selected={openVoteDate}
                    onChange={setOpenVoteDate}
                    showTimeSelect
                    dateFormat="MM/dd/yy h:mm aa"
                    className="w-full bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md px-3 py-2 text-sm"
                    placeholderText="Open vote..."
                  />
                </div>
                <div>
                  <DatePicker
                    selected={closeVoteDate}
                    onChange={setCloseVoteDate}
                    showTimeSelect
                    dateFormat="MM/dd/yy h:mm aa"
                    className="w-full bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md px-3 py-2 text-sm"
                    placeholderText="Close vote..."
                    minDate={openVoteDate || undefined}
                  />
                </div>
              </div>

              {/* Votes Active Indicator */}
              <div className="flex items-center space-x-2 text-xs bg-gray-50 dark:bg-gray-700 p-2 rounded-md">
                <div className={`h-2 w-2 rounded-full ${selectedProposal?.votesactive ? 'bg-green-500' : 'bg-gray-400'}`} />
                <span>Votes {selectedProposal?.votesactive ? 'Active' : 'Inactive'}</span>
              </div>

              <button
                type="submit"
                className="w-full bg-primary hover:bg-primary/90 text-white rounded-md py-2 text-sm font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!selectedStatusId}
              >
                Update Proposal
              </button>
            </form>
          </div>

          {/* Right Column - Info */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div className="col-span-2 font-medium text-gray-500 dark:text-gray-400 mb-2">Current Status</div>
              
              <InfoItem 
                label="Status" 
                value={statuses.find(s => s.id === selectedProposal?.status)?.name || 'Unknown'} 
              />
              <InfoItem 
                label="Reviewed" 
                value={selectedProposal?.reviewed ? 'Yes' : 'No'} 
              />
              <InfoItem 
                label="Approved" 
                value={selectedProposal?.approved ? 'Yes' : 'No'} 
              />
              <InfoItem 
                label="Votes" 
                value={selectedProposal?.votesactive ? 'Active' : 'Inactive'} 
              />
              {selectedProposal?.openvote && (
                <InfoItem 
                  label="Opens" 
                  value={new Date(selectedProposal.openvote).toLocaleDateString()} 
                />
              )}
              {selectedProposal?.closevote && (
                <InfoItem 
                  label="Closes" 
                  value={new Date(selectedProposal.closevote).toLocaleDateString()} 
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const InfoItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-700 p-2 rounded-md">
    <span className="text-gray-600 dark:text-gray-300">{label}</span>
    <span className="font-medium">{value}</span>
  </div>
);

export default ProposalActions; 