import React from 'react';

interface TreasuryTabProps {
  updateMessage: string | null;
  setUpdateMessage: (message: string | null) => void;
}

const TreasuryTab: React.FC<TreasuryTabProps> = ({
  updateMessage,
  setUpdateMessage,
}) => {
  return (
    <div className="space-y-8">
      {/* Notification Message */}
      {updateMessage && (
        <div className="p-4 bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 rounded-lg">
          {updateMessage}
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {[
          'manage wallets',
          'add comment',
          'edit comment',
          'update transactions',
          'balance check'
        ].map((action) => (
          <button key={action} className="button-primary capitalize">
            {action}
          </button>
        ))}
      </div>

      <div className="card p-6">
        <div className="text-center text-gray-600 dark:text-gray-300">
          Select an action above to manage treasury
        </div>
      </div>
    </div>
  );
};

export default TreasuryTab; 