import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Election, UpdateElection, ElectionType, ElectionStatus, ElectionPosition } from '../../../types';

interface ElectionListProps {
  elections: Election[];
  selectedElection: Election | null;
  editedElection: UpdateElection | null;
  setEditedElection: (election: UpdateElection | null) => void;
  electionTypes: ElectionType[];
  electionStatuses: ElectionStatus[];
  electionPositions: ElectionPosition[];
  handleElectionSelect: (election: Election) => void;
  handleUpdateElection: () => Promise<void>;
  setSelectedElection: (election: Election | null) => void;
}

const ElectionList: React.FC<ElectionListProps> = ({
  elections,
  selectedElection,
  editedElection,
  setEditedElection,
  electionTypes,
  electionStatuses,
  electionPositions,
  handleElectionSelect,
  handleUpdateElection,
  setSelectedElection,
}) => {
  const handleEditedElectionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (!editedElection) return;

    setEditedElection({
      ...editedElection,
      [name]: type === 'checkbox' 
        ? (e.target as HTMLInputElement).checked 
        : (name === 'type' || name === 'position' || name === 'status' || name === 'firstcandidate' || name === 'secondcandidate') 
          ? Number(value) 
          : value
    });
  };

  if (!selectedElection) {
    return (
      <div className="space-y-4">
        <h3 className="text-xl font-semibold">Review Elections</h3>
        <div className="space-y-4">
          {elections.map(election => (
            <div 
              key={election.id} 
              className="p-4 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
              onClick={() => handleElectionSelect(election)}
            >
              <h4 className="text-lg font-medium">{election.title}</h4>
              <p className="text-gray-600 dark:text-gray-300">{election.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!editedElection) return null;

  return (
    <form className="space-y-4">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={editedElection.title}
          onChange={handleEditedElectionChange}
          className="input-field mt-1 w-full"
        />
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={editedElection.description}
          onChange={handleEditedElectionChange}
          className="input-field mt-1 w-full h-32"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Type
          </label>
          <select
            id="type"
            name="type"
            value={editedElection.type || ''}
            onChange={handleEditedElectionChange}
            className="select-field mt-1 w-full"
          >
            <option value="">Select Type</option>
            {electionTypes.map(type => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Position
          </label>
          <select
            id="position"
            name="position"
            value={editedElection.position || ''}
            onChange={handleEditedElectionChange}
            className="select-field mt-1 w-full"
          >
            <option value="">Select Position</option>
            {electionPositions.map(position => (
              <option key={position.id} value={position.id}>
                {position.title}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Status
          </label>
          <select
            id="status"
            name="status"
            value={editedElection.status || ''}
            onChange={handleEditedElectionChange}
            className="select-field mt-1 w-full"
          >
            <option value="">Select Status</option>
            {electionStatuses.map(status => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Open Vote Date
          </label>
          <DatePicker
            selected={editedElection.openvote ? new Date(editedElection.openvote) : null}
            onChange={(date: Date | null) => {
              setEditedElection({
                ...editedElection,
                openvote: date
              });
            }}
            showTimeSelect
            dateFormat="Pp"
            className="input-field w-full"
            placeholderText="Select open vote date"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Close Vote Date
          </label>
          <DatePicker
            selected={editedElection.closevote ? new Date(editedElection.closevote) : null}
            onChange={(date: Date | null) => {
              setEditedElection({
                ...editedElection,
                closevote: date
              });
            }}
            showTimeSelect
            dateFormat="Pp"
            className="input-field w-full"
            placeholderText="Select close vote date"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="reviewed"
              checked={editedElection.reviewed}
              onChange={handleEditedElectionChange}
              className="form-checkbox"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Reviewed</span>
          </label>
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="approved"
              checked={editedElection.approved}
              onChange={handleEditedElectionChange}
              className="form-checkbox"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Approved</span>
          </label>
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="votesactive"
              checked={editedElection.votesactive}
              onChange={handleEditedElectionChange}
              className="form-checkbox"
            />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Votes Active</span>
          </label>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={() => {
            setSelectedElection(null);
            setEditedElection(null);
          }}
          className="button-secondary"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleUpdateElection}
          className="button-primary"
        >
          Update Election
        </button>
      </div>
    </form>
  );
};

export default ElectionList; 