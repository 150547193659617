import React, { useState, useEffect } from 'react';
import { Election, Candidate, NewCandidate, UpdateCandidate } from '../../../types';
import { getCandidates, createCandidate, updateCandidate, deleteCandidate, assignCandidateToElection, removeCandidateFromElection } from '../../../services/apiService';

interface ElectionCandidatesProps {
  elections: Election[];
  setUpdateMessage: (message: string | null) => void;
}

const CANDIDATE_TYPES = [
  { id: 1, name: 'DRAFT' },
  { id: 2, name: 'Applied' },
  { id: 3, name: 'Active' },
  { id: 4, name: 'Incumbent' },
  { id: 5, name: 'Archived' }
];

const CANDIDATE_STATUSES = [
  { id: 1, name: 'DRAFT' },
  { id: 2, name: 'Submitted' },
  { id: 3, name: 'Reviewed' },
  { id: 4, name: 'Nominated' },
  { id: 5, name: 'Primary' },
  { id: 6, name: 'Election' },
  { id: 7, name: 'Winner' },
  { id: 8, name: 'Lost' },
  { id: 9, name: 'Archived' }
];

const ElectionCandidates: React.FC<ElectionCandidatesProps> = ({
  elections,
  setUpdateMessage,
}) => {
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);
  const [editingCandidate, setEditingCandidate] = useState<UpdateCandidate | null>(null);
  const [selectedElectionForCandidate, setSelectedElectionForCandidate] = useState<Election | null>(null);
  const [newCandidate, setNewCandidate] = useState<NewCandidate>({
    name: '',
    twitter: '',
    discord: '',
    telegram: '',
    type: 0,
    status: 0
  });

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const candidatesData = await getCandidates();
        if (Array.isArray(candidatesData)) {
          setCandidates(candidatesData);
        } else {
          console.error('Candidates data is not an array:', candidatesData);
          setCandidates([]);
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
        setUpdateMessage('Failed to load candidates');
        setCandidates([]);
      }
    };

    fetchCandidates();
  }, [setUpdateMessage]);

  const handleNewCandidateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createCandidate(newCandidate);
      setUpdateMessage('Candidate created successfully');
      // Reset form and refresh candidates
      setNewCandidate({
        name: '',
        twitter: '',
        discord: '',
        telegram: '',
        type: 0,
        status: 0
      });
      const updatedCandidates = await getCandidates();
      setCandidates(updatedCandidates);
    } catch (error) {
      console.error('Error creating candidate:', error);
      setUpdateMessage('Failed to create candidate');
    }
  };

  const handleCandidateUpdate = async () => {
    if (!selectedCandidate?.id || !editingCandidate) return;

    try {
      await updateCandidate(selectedCandidate.id, editingCandidate);
      setUpdateMessage('Candidate updated successfully');
      // Refresh candidates list
      const updatedCandidates = await getCandidates();
      setCandidates(updatedCandidates);
      // Reset selection
      setSelectedCandidate(null);
      setEditingCandidate(null);
    } catch (error) {
      console.error('Error updating candidate:', error);
      setUpdateMessage('Failed to update candidate');
    }
  };

  const handleCandidateDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this candidate? This action cannot be undone.')) {
      try {
        await deleteCandidate(id);
        setUpdateMessage('Candidate deleted successfully');
        // Refresh candidates list
        const updatedCandidates = await getCandidates();
        setCandidates(updatedCandidates);
      } catch (error) {
        console.error('Error deleting candidate:', error);
        setUpdateMessage('Failed to delete candidate');
      }
    }
  };

  const handleAssignCandidate = async (candidateId: number, isFirstCandidate: boolean) => {
    if (!selectedElectionForCandidate?.id) {
      setUpdateMessage('Please select an election first');
      return;
    }
    try {
      await assignCandidateToElection(selectedElectionForCandidate.id, candidateId, isFirstCandidate);
      setUpdateMessage('Candidate assigned to election successfully');
      // Update the selected election with the refreshed data
      const updatedElection = elections.find(e => e.id === selectedElectionForCandidate.id);
      setSelectedElectionForCandidate(updatedElection || null);
    } catch (error) {
      console.error('Error assigning candidate:', error);
      setUpdateMessage('Failed to assign candidate to election');
    }
  };

  const handleRemoveFromElection = async (electionId: number, candidateId: number) => {
    try {
      await removeCandidateFromElection(electionId, candidateId);
      setUpdateMessage('Candidate removed from election successfully');
      // Update the selected election with the refreshed data
      const updatedElection = elections.find(e => e.id === electionId);
      setSelectedElectionForCandidate(updatedElection || null);
      // Scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error removing candidate:', error);
      setUpdateMessage('Failed to remove candidate from election');
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold">Manage Candidates</h3>
      <div className="grid grid-cols-1 gap-4">
        {/* Add/Edit Candidate Form */}
        <div className="card p-4 bg-gray-50 dark:bg-gray-800">
          <h4 className="text-lg font-medium mb-4">
            {selectedCandidate ? 'Edit Candidate' : 'Add New Candidate'}
          </h4>
          <form onSubmit={selectedCandidate ? handleCandidateUpdate : handleNewCandidateSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={selectedCandidate ? editingCandidate?.name || '' : newCandidate.name}
                  onChange={(e) => {
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, name: e.target.value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, name: e.target.value }));
                    }
                  }}
                  className="input-field mt-1 w-full"
                  required
                />
              </div>

              <div>
                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Twitter
                </label>
                <input
                  type="text"
                  id="twitter"
                  name="twitter"
                  value={selectedCandidate ? editingCandidate?.twitter || '' : newCandidate.twitter}
                  onChange={(e) => {
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, twitter: e.target.value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, twitter: e.target.value }));
                    }
                  }}
                  className="input-field mt-1 w-full"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="discord" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Discord
                </label>
                <input
                  type="text"
                  id="discord"
                  name="discord"
                  value={selectedCandidate ? editingCandidate?.discord || '' : newCandidate.discord}
                  onChange={(e) => {
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, discord: e.target.value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, discord: e.target.value }));
                    }
                  }}
                  className="input-field mt-1 w-full"
                />
              </div>

              <div>
                <label htmlFor="telegram" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Telegram
                </label>
                <input
                  type="text"
                  id="telegram"
                  name="telegram"
                  value={selectedCandidate ? editingCandidate?.telegram || '' : newCandidate.telegram}
                  onChange={(e) => {
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, telegram: e.target.value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, telegram: e.target.value }));
                    }
                  }}
                  className="input-field mt-1 w-full"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Type
                </label>
                <select
                  id="type"
                  name="type"
                  value={selectedCandidate ? editingCandidate?.type || 0 : newCandidate.type}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, type: value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, type: value }));
                    }
                  }}
                  className="select-field mt-1 w-full"
                  required
                >
                  <option value={0}>Select Type</option>
                  {CANDIDATE_TYPES.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={selectedCandidate ? editingCandidate?.status || 0 : newCandidate.status}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (selectedCandidate) {
                      setEditingCandidate(prev => ({ ...prev!, status: value }));
                    } else {
                      setNewCandidate(prev => ({ ...prev, status: value }));
                    }
                  }}
                  className="select-field mt-1 w-full"
                  required
                >
                  <option value={0}>Select Status</option>
                  {CANDIDATE_STATUSES.map(status => (
                    <option key={status.id} value={status.id}>{status.name}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Election Assignment Section */}
            {selectedCandidate && (
              <div className="border-t pt-4 mt-4">
                <h4 className="text-lg font-medium mb-4">Assign to Election</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <label htmlFor="election" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Select Election
                    </label>
                    <select
                      id="election"
                      name="election"
                      value={selectedElectionForCandidate?.id || ''}
                      onChange={(e) => {
                        const election = elections.find(el => el.id === Number(e.target.value));
                        setSelectedElectionForCandidate(election || null);
                      }}
                      className="select-field mt-1 w-full"
                    >
                      <option value="">Select Election</option>
                      {elections.map(election => (
                        <option key={election.id} value={election.id}>
                          {election.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleAssignCandidate(selectedCandidate.id, true)}
                    className="button-primary"
                    disabled={!selectedElectionForCandidate}
                  >
                    Assign as First Candidate
                  </button>
                  <button
                    type="button"
                    onClick={() => handleAssignCandidate(selectedCandidate.id, false)}
                    className="button-primary"
                    disabled={!selectedElectionForCandidate}
                  >
                    Assign as Second Candidate
                  </button>
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-4 pt-4">
              {selectedCandidate ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedCandidate(null);
                      setEditingCandidate(null);
                    }}
                    className="button-secondary"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="button-primary">
                    Update Candidate
                  </button>
                </>
              ) : (
                <button type="submit" className="button-primary">
                  Add Candidate
                </button>
              )}
            </div>
          </form>
        </div>

        {/* Existing Candidates List */}
        <div className="card p-4">
          <h4 className="text-lg font-medium mb-4">Existing Candidates</h4>
          <div className="space-y-4">
            {candidates.map(candidate => (
              <div key={candidate.id} className="space-y-2 p-3 bg-white dark:bg-gray-700 rounded-lg shadow">
                {/* Basic Info */}
                <div className="flex items-start justify-between">
                  <div>
                    <h5 className="font-medium">{candidate.name}</h5>
                    <div className="text-sm text-gray-600 dark:text-gray-300 flex gap-3 flex-wrap">
                      {candidate.twitter && <span>Twitter: {candidate.twitter}</span>}
                      {candidate.discord && <span>Discord: {candidate.discord}</span>}
                      {candidate.telegram && <span>Telegram: {candidate.telegram}</span>}
                      <span className="text-gray-500">Created: {new Date(candidate.created!).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setSelectedCandidate(candidate);
                        setEditingCandidate({
                          name: candidate.name || '',
                          twitter: candidate.twitter || '',
                          discord: candidate.discord || '',
                          telegram: candidate.telegram || '',
                          type: candidate.type || 0,
                          status: candidate.status || 0
                        });
                      }}
                      className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleCandidateDelete(candidate.id)}
                      className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                    >
                      Delete
                    </button>
                  </div>
                </div>

                {/* Election Assignment Controls */}
                {selectedElectionForCandidate && (
                  <div className="flex flex-wrap gap-2">
                    {selectedElectionForCandidate.firstcandidate !== candidate.id && 
                     selectedElectionForCandidate.secondcandidate !== candidate.id && (
                      <>
                        <button
                          onClick={() => handleAssignCandidate(candidate.id, true)}
                          className="button-secondary text-xs py-1"
                          disabled={selectedElectionForCandidate.firstcandidate !== null}
                        >
                          Assign as First
                        </button>
                        <button
                          onClick={() => handleAssignCandidate(candidate.id, false)}
                          className="button-secondary text-xs py-1"
                          disabled={selectedElectionForCandidate.secondcandidate !== null}
                        >
                          Assign as Second
                        </button>
                      </>
                    )}
                    {(selectedElectionForCandidate.firstcandidate === candidate.id || 
                      selectedElectionForCandidate.secondcandidate === candidate.id) && (
                      <button
                        onClick={() => handleRemoveFromElection(selectedElectionForCandidate.id!, candidate.id)}
                        className="button-secondary text-xs py-1 bg-red-100 hover:bg-red-200 text-red-700"
                      >
                        Remove from Election
                      </button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionCandidates; 