import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPrimaryElectionById, getElectionCandidates, submitCandidateVote, getCandidateSubmissionFee, submitCandidate, generateCandidateWallet, getConfig } from '../services/apiService';
import toast from 'react-hot-toast';
import { XMarkIcon, ClipboardIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Candidate, PrimaryElectionData, VoteSubmission, DisplayCandidate } from '../types';
import Navbar from '../components/Navbar';
import { Toaster } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { QRCodeSVG } from 'qrcode.react';

const PrimaryDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [primary, setPrimary] = useState<PrimaryElectionData | null>(null);
  const [candidates, setCandidates] = useState<DisplayCandidate[]>([]);
  const [loading, setLoading] = useState(true);
  const [voteAmount, setVoteAmount] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<DisplayCandidate | null>(null);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [showSubmitCandidateModal, setShowSubmitCandidateModal] = useState(false);
  const [candidateSubmissionFee, setCandidateSubmissionFee] = useState<number | null>(null);
  const [govTokenTicker, setGovTokenTicker] = useState<string>('');
  const [candidateData, setCandidateData] = useState({
    name: '',
    description: '',
    twitter: '',
    discord: '',
    telegram: ''
  });
  const [isSubmittingCandidate, setIsSubmittingCandidate] = useState(false);
  const [candidateSubmissionError, setCandidateSubmissionError] = useState<string | null>(null);
  const [candidateSubmissionStep, setCandidateSubmissionStep] = useState<'form' | 'verification'>('form');
  const [verificationResponse, setVerificationResponse] = useState<{
    status: 'pending' | 'completed' | 'failed';
    verificationId: string;
    expectedAmount: number;
    address: string;
  } | null>(null);
  const [candidateWalletData, setCandidateWalletData] = useState<{ address: string; verificationId: string } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [primaryData, candidatesData, feeData] = await Promise.all([
          getPrimaryElectionById(Number(id)),
          getElectionCandidates(),
          getCandidateSubmissionFee()
        ]);

        console.log('Candidate submission fee data:', feeData);
        if (!feeData || typeof feeData.fee !== 'number') {
          console.error('Invalid fee data received:', feeData);
          toast.error('Failed to load submission fee');
          return;
        }

        // Set the submission fee
        setCandidateSubmissionFee(feeData.fee);
        console.log('Set candidate submission fee to:', feeData.fee);

        // Transform primary data to match PrimaryElectionData interface
        const transformedPrimary: PrimaryElectionData = {
          ...primaryData,
          election_types: { name: 'Primary' },
          election_statuses: { name: 'Active' },
          election_positions: {
            title: 'Primary Election',
            description: primaryData.description
          },
          election: primaryData.election || {
            id: primaryData.id,
            title: primaryData.title,
            description: primaryData.description,
            reviewed: primaryData.reviewed,
            approved: primaryData.approved,
            votesactive: primaryData.votesactive,
            openvote: primaryData.openvote,
            closevote: primaryData.closevote,
            created: primaryData.created,
            type: primaryData.type,
            position: primaryData.position,
            status: primaryData.status,
            snapshot: primaryData.snapshot
          }
        };
        setPrimary(transformedPrimary);
        
        // Filter candidates that are part of this primary
        const relevantCandidates = primaryData.candidates 
          ? candidatesData
              .filter((c: Candidate) => primaryData.candidates.includes(c.id))
              .map((c: Candidate): DisplayCandidate => ({
                id: c.id,
                name: c.name,
                description: c.data?.description || '',
                votes_count: c.candidate_votes?.length || 0,
                wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || 
                       c.candidate_wallets_election_candidates_walletTocandidate_wallets?.address || null,
                twitter: c.twitter,
                discord: c.discord,
                telegram: c.telegram,
                created: c.created,
                type: c.type,
                status: c.status
              }))
          : [];
        
        setCandidates(relevantCandidates);
      } catch (error) {
        console.error('Error fetching primary data:', error);
        toast.error('Failed to load primary data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchCandidateSubmissionFee = async () => {
      try {
        const { fee } = await getCandidateSubmissionFee();
        setCandidateSubmissionFee(fee);
      } catch (error) {
        console.error('Error fetching candidate submission fee:', error);
        toast.error('Failed to load candidate submission fee');
      }
    };

    fetchCandidateSubmissionFee();
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfig();
        setGovTokenTicker(config.govTokenTicker);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  const handleVoteSubmit = async () => {
    if (!selectedCandidate || !voteAmount || !transactionHash || !selectedCandidate.wallet) {
      toast.error('Please fill in all required fields');
      return;
    }

    const voteData: VoteSubmission = {
      candidate_id: selectedCandidate.id,
      toaddress: selectedCandidate.wallet,
      amountsent: voteAmount,
      hash: transactionHash,
      created: new Date().toISOString(),
      validvote: true
    };

    try {
      await submitCandidateVote(voteData);
      toast.success('Vote submitted successfully');
      setShowVoteModal(false);
      // Clear inputs
      setVoteAmount('');
      setTransactionHash('');
      setSelectedCandidate(null);
      // Refresh data
      const candidatesData = await getElectionCandidates();
      const updatedCandidates = candidatesData
        .filter((c: Candidate) => 
          c.elections_elections_firstcandidateToelection_candidates?.some(e => e.id === Number(id)) ||
          c.elections_elections_secondcandidateToelection_candidates?.some(e => e.id === Number(id))
        )
        .map((c: Candidate): DisplayCandidate => ({
          id: c.id,
          name: c.name || '',
          description: c.data?.description || '',
          votes_count: c.candidate_votes?.length || 0,
          wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || null,
          twitter: c.twitter || null,
          discord: c.discord || null,
          telegram: c.telegram || null,
          created: c.created,
          type: c.type || null,
          status: c.status || null
        }));
      setCandidates(updatedCandidates);
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Failed to submit vote');
    }
  };

  const handleCandidateSubmit = async () => {
    if (!candidateData.name || !candidateData.description) {
      toast.error('Name and description are required');
      return;
    }

    try {
      setIsSubmittingCandidate(true);
      setCandidateSubmissionError(null);

      // Generate wallet first
      const walletResponse = await generateCandidateWallet();
      setCandidateWalletData(walletResponse);

      // Store verification response for QR code display
      setVerificationResponse({
        status: 'pending',
        verificationId: walletResponse.verificationId,
        expectedAmount: candidateSubmissionFee!,
        address: walletResponse.address
      });

      // Move to verification step
      setCandidateSubmissionStep('verification');
    } catch (error: any) {
      console.error('Error generating wallet:', error);
      setCandidateSubmissionError(
        error.response?.data?.error || 
        error.message || 
        'Failed to generate wallet'
      );
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  const handleVerifyTransaction = async () => {
    if (!verificationResponse?.verificationId || !candidateWalletData) {
      setCandidateSubmissionError('No verification ID or wallet data found');
      return;
    }

    try {
      setIsSubmittingCandidate(true);
      setCandidateSubmissionError(null);

      // Submit the candidate with verification
      await submitCandidate({
        ...candidateData,
        primaryId: Number(id),
        verificationId: verificationResponse.verificationId
      });

      // Refresh both primary and candidates data
      const [primaryData, candidatesData] = await Promise.all([
        getPrimaryElectionById(Number(id)),
        getElectionCandidates()
      ]);

      // Transform primary data
      const transformedPrimary: PrimaryElectionData = {
        ...primaryData,
        election_types: { name: 'Primary' },
        election_statuses: { name: 'Active' },
        election_positions: {
          title: 'Primary Election',
          description: primaryData.description
        },
        election: primaryData.election || {
          id: primaryData.id,
          title: primaryData.title,
          description: primaryData.description,
          reviewed: primaryData.reviewed,
          approved: primaryData.approved,
          votesactive: primaryData.votesactive,
          openvote: primaryData.openvote,
          closevote: primaryData.closevote,
          created: primaryData.created,
          type: primaryData.type,
          position: primaryData.position,
          status: primaryData.status,
          snapshot: primaryData.snapshot
        }
      };
      setPrimary(transformedPrimary);

      // Filter and set candidates
      const relevantCandidates = primaryData.candidates 
        ? candidatesData
            .filter((c: Candidate) => primaryData.candidates.includes(c.id))
            .map((c: Candidate): DisplayCandidate => ({
              id: c.id,
              name: c.name,
              description: c.data?.description || '',
              votes_count: c.candidate_votes?.length || 0,
              wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || 
                     c.candidate_wallets_election_candidates_walletTocandidate_wallets?.address || null,
              twitter: c.twitter,
              discord: c.discord,
              telegram: c.telegram,
              created: c.created,
              type: c.type,
              status: c.status
            }))
        : [];
      
      setCandidates(relevantCandidates);
      
      // Reset form and close modal
      setShowSubmitCandidateModal(false);
      setCandidateData({
        name: '',
        description: '',
        twitter: '',
        discord: '',
        telegram: ''
      });
      setCandidateWalletData(null);
      setVerificationResponse(null);
      setCandidateSubmissionStep('form');
      
      toast.success('Candidate submitted successfully!');
    } catch (error: any) {
      console.error('Error submitting candidate:', error);
      setCandidateSubmissionError(
        error.response?.data?.error || 
        error.message || 
        'Failed to submit candidate'
      );
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  // Candidate submission modal
  const renderCandidateSubmissionModal = () => (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <div className="inline-block transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle max-h-[70vh] overflow-y-auto">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={() => setShowSubmitCandidateModal(false)}
              className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                {candidateSubmissionStep === 'form' ? 'Submit Candidate' : 'Verify Transaction'}
              </h3>
              <div className="mt-2">
                {candidateSubmissionStep === 'form' ? (
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Name *
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={candidateData.name}
                        onChange={(e) => setCandidateData({ ...candidateData, name: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Description *
                      </label>
                      <textarea
                        id="description"
                        value={candidateData.description}
                        onChange={(e) => setCandidateData({ ...candidateData, description: e.target.value })}
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Twitter
                      </label>
                      <input
                        type="text"
                        id="twitter"
                        value={candidateData.twitter}
                        onChange={(e) => setCandidateData({ ...candidateData, twitter: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="discord" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Discord
                      </label>
                      <input
                        type="text"
                        id="discord"
                        value={candidateData.discord}
                        onChange={(e) => setCandidateData({ ...candidateData, discord: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="telegram" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Telegram
                      </label>
                      <input
                        type="text"
                        id="telegram"
                        value={candidateData.telegram}
                        onChange={(e) => setCandidateData({ ...candidateData, telegram: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="rounded-md bg-gray-800 p-4">
                      <div className="flex flex-col">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 min-w-0">
                            <h3 className="text-sm font-medium text-gray-100">
                              Please send exactly {candidateSubmissionFee} {govTokenTicker} to verify your submission
                            </h3>
                            <div className="mt-2 flex items-center space-x-2">
                              <code className="rounded bg-gray-700 px-2 py-1 text-sm text-gray-100 truncate flex-1">
                                {candidateSubmissionFee} {govTokenTicker}
                              </code>
                              <button
                                type="button"
                                onClick={() => {
                                  navigator.clipboard.writeText(`${candidateSubmissionFee}`);
                                  toast.success('Amount copied to clipboard');
                                }}
                                className="rounded p-1 hover:bg-gray-700 flex-shrink-0"
                              >
                                <ClipboardIcon className="h-5 w-5 text-gray-300" />
                              </button>
                            </div>
                            <div className="mt-4 bg-white p-4 rounded-lg flex justify-center">
                              <QRCodeSVG
                                value={`${verificationResponse?.address}?amount=${verificationResponse?.expectedAmount}&ticker=${govTokenTicker}`}
                                size={200}
                              />
                            </div>
                            <div className="mt-2 flex items-center space-x-2">
                              <code className="rounded bg-gray-700 px-2 py-1 text-sm text-gray-100 truncate flex-1">
                                {verificationResponse?.address}
                              </code>
                              <button
                                type="button"
                                onClick={() => {
                                  navigator.clipboard.writeText(verificationResponse?.address || '');
                                  toast.success('Address copied to clipboard');
                                }}
                                className="rounded p-1 hover:bg-gray-700 flex-shrink-0"
                              >
                                <ClipboardIcon className="h-5 w-5 text-gray-300" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {candidateSubmissionError && (
                      <div className="rounded-md bg-red-900 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-200">{candidateSubmissionError}</h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {candidateSubmissionStep === 'form' ? (
              <button
                type="button"
                onClick={handleCandidateSubmit}
                disabled={isSubmittingCandidate || !candidateData.name || !candidateData.description}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {isSubmittingCandidate ? 'Submitting...' : 'Submit'}
              </button>
            ) : (
              <button
                type="button"
                onClick={handleVerifyTransaction}
                disabled={isSubmittingCandidate}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {isSubmittingCandidate ? 'Verifying...' : 'Verify Payment'}
              </button>
            )}
            <button
              type="button"
              onClick={() => setShowSubmitCandidateModal(false)}
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Navbar />
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      </div>
    );
  }

  if (!primary) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Navbar />
        <div className="text-center py-8">
          <p className="text-gray-500 dark:text-gray-400">Primary not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Navbar />
      <Toaster />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Primary Info Card */}
        <div className="card mb-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">Primary: {primary.title}</h2>
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200">
                      {primary.election_types?.name || 'Unknown Type'}
                    </span>
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                      Primary Election
                    </span>
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Created: {primary.created ? new Date(primary.created).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    }) : 'Not available'}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {primary.votesactive && (
                <div className="hidden sm:block">
                  <div className="flex flex-col space-y-2 items-end">
                    <div className="text-sm font-medium text-green-500 dark:text-green-400">
                      Primary Voting Active
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Closes: {primary.closevote ? new Date(primary.closevote).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      }) : 'Not set'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="prose dark:prose-invert max-w-none">
            <ReactMarkdown>{primary.description || ''}</ReactMarkdown>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Status Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4 flex justify-between">
              <span>Primary Status:</span>
              <span>{primary.election_statuses?.name || 'Unknown'}</span>
            </h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Voting Schedule:</span>
                <div className="text-right">
                  {primary.openvote && primary.closevote ? (
                    <>
                      <div className="font-medium">
                        Opens: {new Date(primary.openvote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(primary.openvote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                      <div className="font-medium">
                        Closes: {new Date(primary.closevote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(primary.closevote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                    </>
                  ) : (
                    <span className="font-medium">Pending Schedule</span>
                  )}
                </div>
              </p>
              <p className="flex justify-between">
                <span>Position:</span>
                <span className="font-medium text-right">
                  {primary.election_positions?.title || 'Unknown Position'}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Type:</span>
                <span className="font-medium">
                  {primary.election_types?.name || 'Unknown Type'}
                </span>
              </p>
            </div>
          </div>

          {/* Candidates Progress Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4">Primary Progress</h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Total Candidates:</span>
                <span className="font-medium">{candidates.length}</span>
              </p>
              <p className="flex justify-between">
                <span>Total Votes:</span>
                <span className="font-medium">
                  {candidates.reduce((sum, candidate) => sum + (candidate.votes_count || 0), 0)}
                </span>
              </p>
              {primary.votesactive && (
                <div className="mt-4">
                  <div className="text-center text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Voting is currently active
                  </div>
                  <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                    <div className="bg-primary h-2.5 rounded-full" style={{ width: '100%' }}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Candidates Card */}
        <div className="card">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-xl font-bold">Primary Candidates</h4>
            <button 
              onClick={() => setShowSubmitCandidateModal(true)}
              className="button-primary"
            >
              Submit Candidate
            </button>
          </div>
          <div className="space-y-4">
            {candidates.map((candidate) => (
              <div key={candidate.id} className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <h5 className="font-medium">{candidate.name}</h5>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {candidate.votes_count || 0} votes
                  </span>
                </div>
                {candidate.description && (
                  <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
                    {candidate.description}
                  </p>
                )}
                <div className="flex flex-col space-y-2 mb-3">
                  {/* Social Links */}
                  <div className="flex space-x-4">
                    {candidate.twitter && (
                      <a
                        href={`https://twitter.com/${candidate.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                      >
                        Twitter
                      </a>
                    )}
                    {candidate.discord && (
                      <span className="text-sm text-gray-600 dark:text-gray-300">
                        Discord: {candidate.discord}
                      </span>
                    )}
                    {candidate.telegram && (
                      <a
                        href={`https://t.me/${candidate.telegram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                      >
                        Telegram
                      </a>
                    )}
                  </div>
                </div>
                {primary.votesactive && (
                  <button
                    onClick={() => {
                      setSelectedCandidate(candidate);
                      setShowVoteModal(true);
                    }}
                    className="button-primary w-full"
                  >
                    Vote for Candidate
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Vote Modal */}
        {showVoteModal && selectedCandidate && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 relative">
              <button
                onClick={() => setShowVoteModal(false)}
                className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
              </button>
              <h3 className="text-xl font-bold mb-4">Vote for {selectedCandidate.name} in Primary</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Transaction Hash
                  </label>
                  <input
                    type="text"
                    value={transactionHash}
                    onChange={(e) => setTransactionHash(e.target.value)}
                    className="input-field w-full"
                    placeholder="Enter transaction hash"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Vote Amount ({govTokenTicker})
                  </label>
                  <input
                    type="number"
                    value={voteAmount}
                    onChange={(e) => setVoteAmount(e.target.value)}
                    className="input-field w-full"
                    placeholder={`Enter vote amount in ${govTokenTicker}`}
                    min="0"
                    step="0.00000001"
                  />
                </div>
                <button
                  onClick={handleVoteSubmit}
                  className="btn-primary w-full"
                >
                  Submit Primary Vote
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Candidate Submission Modal */}
        {showSubmitCandidateModal && renderCandidateSubmissionModal()}
      </div>
    </div>
  );
};

export default PrimaryDetail; 