import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getElectionById, getElectionCandidates, submitCandidateVote } from '../services/apiService';
import toast from 'react-hot-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Candidate, Election, ElectionData, VoteSubmission, DisplayCandidate } from '../types';
import Navbar from '../components/Navbar';
import { Toaster } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';

const ElectionDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [election, setElection] = useState<ElectionData | null>(null);
  const [candidates, setCandidates] = useState<DisplayCandidate[]>([]);
  const [loading, setLoading] = useState(true);
  const [voteAmount, setVoteAmount] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<DisplayCandidate | null>(null);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [electionData, candidatesData] = await Promise.all([
          getElectionById(Number(id)),
          getElectionCandidates()
        ]);

        // Check if this is a regular election
        if ('candidates' in electionData) {
          throw new Error('Not a regular election');
        }

        const election = electionData as Election;

        // Transform election data to match ElectionData interface
        const transformedElection: ElectionData = {
          ...election,
          election_types: { name: 'Election' },
          election_statuses: { name: 'Active' },
          election_positions: {
            title: 'Election Position',
            description: election.description
          }
        };
        setElection(transformedElection);
        
        // Filter candidates for this election
        const relevantCandidates = candidatesData
          .filter((c: Candidate) => 
            election.firstcandidate === c.id ||
            election.secondcandidate === c.id
          )
          .map((c: Candidate): DisplayCandidate => ({
            id: c.id,
            name: c.name,
            description: c.data?.description || '',
            votes_count: c.candidate_votes?.length || 0,
            wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || 
                   c.candidate_wallets_election_candidates_walletTocandidate_wallets?.address || null,
            twitter: c.twitter,
            discord: c.discord,
            telegram: c.telegram,
            created: c.created,
            type: c.type,
            status: c.status
          }));
        
        setCandidates(relevantCandidates);
      } catch (error) {
        console.error('Error fetching election data:', error);
        toast.error('Failed to load election data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleVoteSubmit = async () => {
    if (!selectedCandidate || !voteAmount || !transactionHash || !selectedCandidate.wallet) {
      toast.error('Please fill in all required fields');
      return;
    }

    const voteData: VoteSubmission = {
      candidate_id: selectedCandidate.id,
      toaddress: selectedCandidate.wallet,
      amountsent: voteAmount,
      hash: transactionHash,
      created: new Date().toISOString(),
      validvote: true
    };

    try {
      await submitCandidateVote(voteData);
      toast.success('Vote submitted successfully');
      setShowVoteModal(false);
      // Clear inputs
      setVoteAmount('');
      setTransactionHash('');
      setSelectedCandidate(null);
      // Refresh data
      const candidatesData = await getElectionCandidates();
      const updatedCandidates = candidatesData
        .filter((c: Candidate) => 
          c.elections_elections_firstcandidateToelection_candidates?.some(e => e.id === Number(id)) ||
          c.elections_elections_secondcandidateToelection_candidates?.some(e => e.id === Number(id))
        )
        .map((c: Candidate): DisplayCandidate => ({
          id: c.id,
          name: c.name,
          description: c.data?.description || '',
          votes_count: c.candidate_votes?.length || 0,
          wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || null,
          twitter: c.twitter,
          discord: c.discord,
          telegram: c.telegram,
          created: c.created,
          type: c.type,
          status: c.status
        }));
      setCandidates(updatedCandidates);
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Failed to submit vote');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Navbar />
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      </div>
    );
  }

  if (!election) {
    return (
      <div className="min-h-screen bg-gray-900">
        <Navbar />
        <div className="text-center py-8">
          <p className="text-gray-500 dark:text-gray-400">Election not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Navbar />
      <Toaster />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Election Info Card */}
        <div className="card mb-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">{election.title}</h2>
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200">
                      {election.election_types?.name || 'Unknown Type'}
                    </span>
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                      {election.election_positions?.title || 'Unknown Position'}
                    </span>
                    {election.reviewed && (
                      <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                        Reviewed
                      </span>
                    )}
                    {election.approved && (
                      <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                        Approved
                      </span>
                    )}
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Created: {election.created ? new Date(election.created).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    }) : 'Not available'}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {election.votesactive && (
                <div className="hidden sm:block">
                  <div className="flex flex-col space-y-2 items-end">
                    <div className="text-sm font-medium text-green-500 dark:text-green-400">
                      Voting Active
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Closes: {election.closevote ? new Date(election.closevote).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      }) : 'Not set'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="prose dark:prose-invert max-w-none">
            <ReactMarkdown>{election.description || ''}</ReactMarkdown>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Status Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4 flex justify-between">
              <span>Election Status:</span>
              <span>{election.election_statuses?.name || 'Unknown'}</span>
            </h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Voting Schedule:</span>
                <div className="text-right">
                  {election.openvote && election.closevote ? (
                    <>
                      <div className="font-medium">
                        Opens: {new Date(election.openvote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(election.openvote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                      <div className="font-medium">
                        Closes: {new Date(election.closevote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(election.closevote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                    </>
                  ) : (
                    <span className="font-medium">Pending Schedule</span>
                  )}
                </div>
              </p>
              <p className="flex justify-between">
                <span>Snapshot Taken:</span>
                <span className="font-medium">
                  {election.snapshot ? new Date(election.snapshot).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  }) : 'Pending'}
                </span>
              </p>
            </div>
          </div>

          {/* Candidates Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4">Candidates</h4>
            <div className="space-y-4">
              {candidates.map((candidate) => (
                <div key={candidate.id} className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <h5 className="font-medium">{candidate.name}</h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {candidate.votes_count || 0} votes
                    </span>
                  </div>
                  {candidate.description && (
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
                      {candidate.description}
                    </p>
                  )}
                  <div className="flex flex-col space-y-2 mb-3">
                    {/* Social Links */}
                    <div className="flex space-x-4">
                      {candidate.twitter && (
                        <a
                          href={`https://twitter.com/${candidate.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                        >
                          Twitter
                        </a>
                      )}
                      {candidate.discord && (
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                          Discord: {candidate.discord}
                        </span>
                      )}
                      {candidate.telegram && (
                        <a
                          href={`https://t.me/${candidate.telegram}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                        >
                          Telegram
                        </a>
                      )}
                    </div>
                    {/* Wallet Address */}
                    {candidate.wallet && (
                      <div className="text-sm">
                        <a
                          href={`https://kas.fyi/address/${candidate.wallet}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-600 hover:text-primary-700 dark:text-primary-400 font-mono text-xs"
                        >
                          {`${candidate.wallet.slice(0, 10)}...${candidate.wallet.slice(-8)}`}
                        </a>
                      </div>
                    )}
                    {/* Candidate Info */}
                    <div className="flex space-x-4 text-xs text-gray-500 dark:text-gray-400">
                      <span>Joined: {candidate.created ? new Date(candidate.created).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      }) : 'Unknown'}</span>
                      <span>•</span>
                      <span>Status: {candidate.status}</span>
                    </div>
                  </div>
                  {election.votesactive && (
                    <button
                      onClick={() => {
                        setSelectedCandidate(candidate);
                        setShowVoteModal(true);
                      }}
                      className="button-primary w-full"
                    >
                      Vote for Candidate
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Position Details Card */}
        <div className="card mb-8">
          <h4 className="text-xl font-bold mb-4">Position Details</h4>
          <div className="space-y-4">
            <div>
              <h5 className="font-medium text-lg mb-2">{election.election_positions?.title || 'Position Not Set'}</h5>
              {election.election_positions?.description && election.election_positions.description !== 'TBA' && (
                <p className="text-gray-600 dark:text-gray-300">
                  {election.election_positions.description}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Vote Modal */}
        {showVoteModal && selectedCandidate && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 relative">
              <button
                onClick={() => setShowVoteModal(false)}
                className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
              </button>
              <h3 className="text-xl font-bold mb-4">Vote for {selectedCandidate.name}</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Transaction Hash
                  </label>
                  <input
                    type="text"
                    value={transactionHash}
                    onChange={(e) => setTransactionHash(e.target.value)}
                    className="input-field w-full"
                    placeholder="Enter transaction hash"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Vote Amount
                  </label>
                  <input
                    type="number"
                    value={voteAmount}
                    onChange={(e) => setVoteAmount(e.target.value)}
                    className="input-field w-full"
                    placeholder="Enter vote amount"
                    min="0"
                    step="0.00000001"
                  />
                </div>
                <button
                  onClick={handleVoteSubmit}
                  className="btn-primary w-full"
                >
                  Submit Vote
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElectionDetail;