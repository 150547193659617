import React from 'react';
import { NewElection, ElectionType, ElectionStatus, ElectionPosition } from '../../../types';

interface ElectionFormProps {
  newElection: NewElection;
  setNewElection: (election: NewElection) => void;
  electionTypes: ElectionType[];
  electionStatuses: ElectionStatus[];
  electionPositions: ElectionPosition[];
  handleElectionSubmit: (e: React.FormEvent) => Promise<void>;
}

const ElectionForm: React.FC<ElectionFormProps> = ({
  newElection,
  setNewElection,
  electionTypes,
  electionStatuses,
  electionPositions,
  handleElectionSubmit,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const updatedValue = name === 'type' || name === 'position' || name === 'status' ? Number(value) : value;
    setNewElection({
      ...newElection,
      [name]: updatedValue
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold">Create New Election</h3>
      <form onSubmit={handleElectionSubmit} className="space-y-4">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={newElection.title}
            onChange={handleInputChange}
            className="input-field mt-1 w-full"
            required
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={newElection.description}
            onChange={handleInputChange}
            className="input-field mt-1 w-full h-32"
            required
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Type
            </label>
            <select
              id="type"
              name="type"
              value={newElection.type}
              onChange={handleInputChange}
              className="select-field mt-1 w-full"
              required
            >
              <option value="">Select Type</option>
              {electionTypes.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Position
            </label>
            <select
              id="position"
              name="position"
              value={newElection.position}
              onChange={handleInputChange}
              className="select-field mt-1 w-full"
              required
            >
              <option value="">Select Position</option>
              {electionPositions.map(position => (
                <option key={position.id} value={position.id}>
                  {position.title}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Status
            </label>
            <select
              id="status"
              name="status"
              value={newElection.status}
              onChange={handleInputChange}
              className="select-field mt-1 w-full"
              required
            >
              <option value="">Select Status</option>
              {electionStatuses.map(status => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-end">
          <button type="submit" className="button-primary">
            Create Election
          </button>
        </div>
      </form>
    </div>
  );
};

export default ElectionForm; 