import React, { useState, useEffect } from 'react';
import { getTreasuryWallets, getTreasuryWalletTransactions } from '../services/apiService';
import { TreasuryTransaction } from '../types';

interface TreasuryWallet {
  name: string;
  address: string;
}

const TreasuryTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [wallets, setWallets] = useState<TreasuryWallet[]>([]);
  const [transactions, setTransactions] = useState<TreasuryTransaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch treasury wallets
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const fetchedWallets = await getTreasuryWallets();
        setWallets(fetchedWallets);
        if (fetchedWallets.length > 0) {
          setActiveTab(fetchedWallets[0].address);
        }
      } catch (err) {
        setError('Failed to fetch treasury wallets');
        console.error('Error fetching wallets:', err);
      }
    };
    fetchWallets();
  }, []);

  // Fetch transactions for active wallet
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!activeTab) return;
      
      setLoading(true);
      try {
        const fetchedTransactions = await getTreasuryWalletTransactions(activeTab);
        setTransactions(fetchedTransactions);
        setError(null);
      } catch (err) {
        setError('Failed to fetch transactions');
        console.error('Error fetching transactions:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [activeTab]);

  const toggleRow = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-4 animate-fadeIn">
      {/* Tab Navigation */}
      <div className="flex flex-wrap gap-2 border-b border-gray-200 dark:border-gray-700">
        {wallets.map((wallet) => (
          <button
            key={wallet.address}
            className={`px-6 py-3 text-sm font-medium rounded-t-lg transition-all duration-200
              ${activeTab === wallet.address 
                ? 'bg-primary text-white' 
                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'}`}
            onClick={() => setActiveTab(wallet.address)}
          >
            {wallet.name}
          </button>
        ))}
      </div>

      {/* Loading State */}
      {loading && (
        <div className="p-4 text-center text-gray-500">
          Loading transactions...
        </div>
      )}

      {/* Table */}
      {!loading && transactions.length === 0 && (
        <div className="p-4 text-center text-gray-500">
          No transactions found for this wallet.
        </div>
      )}

      {!loading && transactions.length > 0 && (
        <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Transaction ID
                </th>
                <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Direction
                </th>
                <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Ticker
                </th>
                <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
              {transactions.map((transaction) => (
                <React.Fragment key={transaction.id}>
                  <tr
                    className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 cursor-pointer"
                    onClick={() => toggleRow(transaction.id)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className="font-mono">{transaction.hash}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className={`font-medium ${transaction.amount >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {transaction.amount >= 0 ? 'Receive' : 'Send'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className={`font-medium ${transaction.amount >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {Math.abs(transaction.amount)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-gray-500 dark:text-gray-300">
                      {transaction.ticker}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-gray-500 dark:text-gray-300">
                      {transaction.type}
                    </td>
                  </tr>
                  {expandedRow === transaction.id && (
                    <tr className="bg-gray-50 dark:bg-gray-800">
                      <td colSpan={4} className="px-6 py-4">
                        <div className="card">
                          <h4 className="font-medium mb-2">Transaction Details</h4>
                          <p className="text-gray-600 dark:text-gray-300">
                            {transaction.description}
                          </p>
                          <a 
                            href={`https://kas.fyi/transaction/${transaction.hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-primary hover:text-primary-dark mt-2 inline-block"
                          >
                            View on Explorer
                          </a>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TreasuryTabs;