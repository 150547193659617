import React, { useState } from 'react';
import { Proposal } from '../../../types';
import { burnKRC20, burnKaspa, returnGov, dropGas } from '../../../services/apiService';

interface ProposalWalletOperationsProps {
  proposals: Proposal[];
  setUpdateMessage: (message: string | null) => void;
  selectedProposal: Proposal | null;
}

const ProposalWalletOperations: React.FC<ProposalWalletOperationsProps> = ({
  proposals,
  setUpdateMessage,
  selectedProposal,
}) => {
  const [burnAmount, setBurnAmount] = useState<string>('');
  const [burnTicker, setBurnTicker] = useState<string>('');
  const [gasDropAmount, setGasDropAmount] = useState<string>('');

  const handleBurnKRC20 = async (walletId: number) => {
    try {
      await burnKRC20({ wallet: walletId.toString(), amount: Number(burnAmount), ticker: burnTicker });
      setUpdateMessage('KRC20 tokens burned successfully');
      setBurnAmount('');
      setBurnTicker('');
    } catch (error) {
      console.error('Error burning KRC20:', error);
      setUpdateMessage('Failed to burn KRC20 tokens');
    }
  };

  const handleBurnKaspa = async (walletId: number) => {
    try {
      await burnKaspa({ wallet: walletId.toString(), amount: Number(burnAmount), ticker: 'KAS' });
      setUpdateMessage('Kaspa burned successfully');
      setBurnAmount('');
    } catch (error) {
      console.error('Error burning Kaspa:', error);
      setUpdateMessage('Failed to burn Kaspa');
    }
  };

  const handleReturnGov = async (walletId: number) => {
    try {
      await returnGov({ wallet: walletId.toString(), amount: Number(burnAmount), ticker: 'GOV' });
      setUpdateMessage('GOV tokens returned successfully');
      setBurnAmount('');
    } catch (error) {
      console.error('Error returning GOV:', error);
      setUpdateMessage('Failed to return GOV tokens');
    }
  };

  const handleDropGas = async (walletId: number) => {
    try {
      await dropGas({ wallet: walletId.toString(), amount: Number(gasDropAmount) });
      setUpdateMessage('Gas dropped successfully');
      setGasDropAmount('');
    } catch (error) {
      console.error('Error dropping gas:', error);
      setUpdateMessage('Failed to drop gas');
    }
  };

  if (!selectedProposal?.proposal_wallets_proposals_walletToproposal_wallets) {
    return (
      <div className="p-6 text-center">
        <p className="text-gray-500 dark:text-gray-400">Select a proposal with an active wallet to manage operations</p>
      </div>
    );
  }

  const wallet = selectedProposal.proposal_wallets_proposals_walletToproposal_wallets;

  return (
    <div className="p-6 space-y-8">
      {/* Wallet Info */}
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-xl font-semibold mb-2">{selectedProposal.title}</h3>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">Balance:</span>
            <span className="text-lg font-medium">{wallet.balance || '0'} KAS</span>
          </div>
          <div className="flex items-center gap-2 mt-1">
            <span className="text-sm text-gray-500 dark:text-gray-400">Address:</span>
            <code className="text-sm font-mono bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded">
              {wallet.address}
            </code>
            <a 
              href={`https://explorer.kaspa.org/addresses/${wallet.address}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:text-primary-dark"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* Operations */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* KRC20 Operations */}
        <div className="space-y-4">
          <h4 className="text-lg font-medium">KRC20 Token Operations</h4>
          <div className="flex gap-3">
            <input
              type="number"
              placeholder="Amount"
              className="input-field flex-1"
              value={burnAmount}
              onChange={(e) => setBurnAmount(e.target.value)}
            />
            <input
              type="text"
              placeholder="Token"
              className="input-field w-28"
              value={burnTicker}
              onChange={(e) => setBurnTicker(e.target.value.toUpperCase())}
            />
            <button
              onClick={() => handleBurnKRC20(wallet.id)}
              className="button-primary whitespace-nowrap px-6"
              disabled={!burnAmount || !burnTicker}
            >
              Burn
            </button>
          </div>
        </div>

        {/* Quick Actions */}
        <div className="space-y-4">
          <h4 className="text-lg font-medium">Quick Actions</h4>
          <div className="space-y-3">
            <div className="flex gap-3">
              <input
                type="number"
                placeholder="Amount"
                className="input-field flex-1"
                value={burnAmount}
                onChange={(e) => setBurnAmount(e.target.value)}
              />
              <button
                onClick={() => handleBurnKaspa(wallet.id)}
                className="button-primary w-28"
                disabled={!burnAmount}
              >
                Burn KAS
              </button>
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                placeholder="Amount"
                className="input-field flex-1"
                value={burnAmount}
                onChange={(e) => setBurnAmount(e.target.value)}
              />
              <button
                onClick={() => handleReturnGov(wallet.id)}
                className="button-primary w-28"
                disabled={!burnAmount}
              >
                Return GOV
              </button>
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                placeholder="Amount"
                className="input-field flex-1"
                value={gasDropAmount}
                onChange={(e) => setGasDropAmount(e.target.value)}
              />
              <button
                onClick={() => handleDropGas(wallet.id)}
                className="button-primary w-28"
                disabled={!gasDropAmount}
              >
                Drop Gas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalWalletOperations; 