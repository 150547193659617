import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import {
  getProposalById,
  getProposalNominationFee,
  getProposalEditFee,
  getProposalNominations,
  getProposalStatuses,
  verifyNominationTransaction,
  getNominationVerificationStatus,
  submitProposalYesVote,
  submitProposalNoVote,
  getConfig,
  getSnapshotById,
  verifyProposalEdit
} from '../services/apiService';
import { Proposal, Status, Config, ProposalDetailProps, SnapshotData } from '../types';
import ReactMarkdown from 'react-markdown';
import QRCode from 'react-qr-code';
import { toast, Toaster } from 'react-hot-toast';
import { XMarkIcon, ClipboardIcon, ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const ProposalDetail: React.FC<ProposalDetailProps> = ({ initialProposal, onRefresh }) => {
  const { id } = useParams<{ id: string }>();
  const [proposal, setProposal] = useState<Proposal | null>(initialProposal || null);
  const [config, setConfig] = useState<Config | null>(null);
  const [showVoteYesPopup, setShowVoteYesPopup] = useState(false);
  const [showVoteNoPopup, setShowVoteNoPopup] = useState(false);
  const [showNominatePopup, setShowNominatePopup] = useState(false);
  const [nominationError, setNominationError] = useState<string | null>(null);
  const [nominationFee, setNominationFee] = useState<number | null>(null);
  const [nominations, setNominations] = useState<any[]>([]);
  const [yesVotes, setYesVotes] = useState<any[]>([]);
  const [noVotes, setNoVotes] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationProgress, setVerificationProgress] = useState<{ attempt: number; maxAttempts: number; verificationId?: string } | null>(null);
  const [voteAmount, setVoteAmount] = useState<string>('');
  const [estimatedVotes, setEstimatedVotes] = useState<number | null>(null);
  const [powerLevel, setPowerLevel] = useState<string>('');
  const [transactionHash, setTransactionHash] = useState<string>('');
  const [snapshotData, setSnapshotData] = useState<SnapshotData | null>(null);
  const [isLoadingSnapshot, setIsLoadingSnapshot] = useState(false);
  const navigate = useNavigate();

  // Add new state for edit functionality
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTransactionHash, setEditTransactionHash] = useState('');
  const [editFee, setEditFee] = useState<number | null>(null);
  const [editTokenTicker, setEditTokenTicker] = useState('');
  const [isEditLoading, setIsEditLoading] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configData = await getConfig();
        setConfig(configData);
      } catch (error) {
        console.error('Error fetching config:', error);
        toast.error('Error loading wallet addresses');
      }
    };

    fetchConfig();
  }, []);

  // Add fetchEditFee function
  const fetchEditFee = async () => {
    try {
      const { fee } = await getProposalEditFee();
      setEditFee(fee);
    } catch (error) {
      console.error('Error fetching edit fee:', error);
      toast.error('Failed to load edit fee');
    }
  };

  useEffect(() => {
    const fetchProposalDetail = async () => {
      if (!id) return;

      try {
        // First fetch the proposal and statuses
        const [proposalData, statusesData] = await Promise.all([
          getProposalById(Number(id)),
          getProposalStatuses()
        ]);

        if (!proposalData) {
          console.error('Proposal not found');
          return;
        }

        setProposal(proposalData);
        setStatuses(statusesData);

        // Only after we have a valid proposal, fetch nominations
        try {
          const nominationsData = await getProposalNominations(Number(id));
          setNominations(nominationsData);
          // Set votes from the proposal data
          setYesVotes(Array.isArray(proposalData.yes_votes) ? proposalData.yes_votes : []);
          setNoVotes(Array.isArray(proposalData.no_votes) ? proposalData.no_votes : []);
        } catch (error) {
          console.error('Error fetching nominations:', error);
          // Don't fail completely if nominations fail to load
          setNominations([]);
          setYesVotes([]);
          setNoVotes([]);
        }
      } catch (error) {
        console.error('Error fetching proposal:', error);
      }
    };

    const fetchNominationFee = async () => {
      try {
        const { fee } = await getProposalNominationFee();
        setNominationFee(fee);
      } catch (error) {
        console.error('Error fetching nomination fee:', error);
      }
    };

    fetchProposalDetail();
    fetchNominationFee();
    fetchEditFee();
  }, [id]);

  useEffect(() => {
    const fetchSnapshotData = async () => {
      if (!proposal?.snapshot) return;

      setIsLoadingSnapshot(true);
      try {
        const response = await getSnapshotById(proposal.snapshot);
        setSnapshotData(response);
      } catch (error) {
        console.error('Error fetching snapshot data:', error);
      } finally {
        setIsLoadingSnapshot(false);
      }
    };

    fetchSnapshotData();
  }, [proposal?.snapshot]);

  // Get status name
  const getStatusName = (statusId: number) => {
    const status = statuses.find(s => s.id === statusId);
    return status ? status.name : 'Unknown Status';
  };

  // Calculate progress percentages
  const nominationProgress = Math.min((nominations.length / 10) * 100, 100);
  const totalYesVotes = yesVotes.reduce((sum, vote) => sum + (vote.votescounted || 0), 0);
  const totalNoVotes = noVotes.reduce((sum, vote) => sum + (vote.votescounted || 0), 0);
  const totalVotesCounted = totalYesVotes + totalNoVotes;
  const yesPercentage = totalVotesCounted > 0 ? (totalYesVotes / totalVotesCounted) * 100 : 50;
  const noPercentage = totalVotesCounted > 0 ? (totalNoVotes / totalVotesCounted) * 100 : 50;

  // Add calculator function
  const calculateVotePower = (amount: string) => {
    const numAmount = parseFloat(amount);
    const minFee = config?.proposals?.votingFeeMin || 0.1;
    const maxFee = config?.proposals?.votingFeeMax || 100000;

    if (isNaN(numAmount) || numAmount < minFee) {
      setEstimatedVotes(null);
      setPowerLevel('');
      return;
    }

    // Use the exact same constants as backend
    const SEMITONE_RATIO = Math.pow(2, 1 / 12);  // ≈1.059463

    // Normalize amount and apply cap exactly as backend does
    const normalizedAmount = Math.min(numAmount / minFee, maxFee / minFee);

    // Calculate musical octaves and votes using identical formula to backend
    const octaves = Math.log2(normalizedAmount);
    const semitones = octaves * 12;

    const votes = Math.floor(
      Math.pow(2, octaves * (2 / 3)) *
      Math.pow(SEMITONE_RATIO, semitones % 12)
    );

    // Calculate max votes for power level thresholds using same formula as backend
    const maxVotes = Math.floor(
      Math.pow(2, Math.log2(maxFee / minFee) * (2 / 3))
    );

    // Use exact same thresholds as backend
    const pianoThreshold = Math.floor(maxVotes * 0.05);        // 5% of max
    const mezzoPianoThreshold = Math.floor(maxVotes * 0.15);   // 15% of max  
    const mezzoForteThreshold = Math.floor(maxVotes * 0.35);   // 35% of max
    const forteThreshold = Math.floor(maxVotes * 0.70);        // 70% of max

    // Determine power level using identical logic to backend
    let level = '';
    if (votes <= pianoThreshold) level = 'Piano';
    else if (votes <= mezzoPianoThreshold) level = 'Mezzo-Piano';
    else if (votes <= mezzoForteThreshold) level = 'Mezzo-Forte';
    else if (votes <= forteThreshold) level = 'Forte';
    else level = 'Fortissimo';

    setEstimatedVotes(votes);
    setPowerLevel(level);
  };

  const handleYesVoteSubmit = async (transactionHash: string) => {
    if (!proposal) {
      toast.error('No proposal found');
      return;
    }
    try {
      await submitProposalYesVote(proposal.id, transactionHash);
      toast.success('Yes vote submitted successfully!');
      // Refresh proposal data after vote
      if (onRefresh) {
        await onRefresh();
      }
    } catch (error: any) {
      // Just use the error message from the API response
      toast.error(error.response?.data?.error || 'Failed to submit yes vote');
    }
  };

  const handleNoVoteSubmit = async (transactionHash: string) => {
    if (!proposal) {
      toast.error('No proposal found');
      return;
    }
    try {
      await submitProposalNoVote(proposal.id, transactionHash);
      toast.success('No vote submitted successfully!');
      // Refresh proposal data after vote
      if (onRefresh) {
        await onRefresh();
      }
    } catch (error: any) {
      // Just use the error message from the API response
      toast.error(error.response?.data?.error || 'Failed to submit no vote');
    }
  };

  // Helper function to shorten hash/address with 5 digits at each end
  const shortenString = (str: string, digits: number = 5) => {
    if (!str || str.length <= digits * 2) return str;
    return `${str.slice(0, digits)}...${str.slice(-digits)}`;
  };

  // Add helper function to format date
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  // Add helper function to format number with commas
  const formatNumber = (num: number) => {
    return num.toLocaleString('en-US');
  };

  // Add new function to handle edit verification
  const handleVerifyEdit = async () => {
    if (!proposal || !editTransactionHash || !editFee) {
      toast.error('Missing required information');
      return;
    }

    setIsEditLoading(true);
    try {
      const response = await verifyProposalEdit(proposal.id, editTransactionHash, editFee);
      if (response.nomination) {
        toast.success('Edit verification successful');
        navigate(`/edit-proposal/${proposal.id}?verificationHash=${response.nomination.hash}`);
      } else {
        toast.error('Verification failed');
      }
    } catch (error) {
      console.error('Error verifying edit:', error);
      toast.error('Failed to verify edit');
    } finally {
      setIsEditLoading(false);
      setIsEditModalOpen(false);
      setEditTransactionHash('');
    }
  };

  // Add new function to handle edit button click
  const handleEditClick = async () => {
    setIsEditModalOpen(true);

    // Use the existing edit fee data
    if (editFee) {
      setEditFee(editFee);
      setEditTokenTicker(process.env.REACT_APP_GOV_TOKEN_TICKER || 'GOV');
    } else {
      toast.error('Failed to load edit fee data');
      setIsEditModalOpen(false);
    }
  };

  if (!proposal) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Navbar />
      <Toaster />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Creator Card */}
        <div className="card mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-3">Proposal Creator</h3>
                {nominations && nominations.length > 0 ? (
                  <div className="flex flex-col space-y-2">
                    <p className="font-mono text-sm text-gray-600 dark:text-gray-300">
                      {shortenString(nominations[0].fromaddress, 8)}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Submitted: {new Date(nominations[0].created).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </p>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500 dark:text-gray-400">No nominations yet</p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4">
              {nominations && nominations.length > 0 && (
                <div className="hidden sm:block w-full">
                  <div className="flex flex-col space-y-2 items-end">
                    <a
                      href={`https://kas.fyi/address/${proposal.proposal_wallets_proposals_walletToproposal_wallets?.address}?view=token_transfer`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-primary-600 bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:text-primary-200 dark:hover:bg-primary-800 transition-colors duration-200"
                    >
                      <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                      Proposal Wallet
                    </a>
                    {nominations.length > 0 && !proposal.openvote && !proposal.closevote && (
                      <button
                        onClick={handleEditClick}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-primary-600 bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:text-primary-200 dark:hover:bg-primary-800 transition-colors duration-200"
                      >
                        <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        Edit Proposal
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="card">
            <h4 className="text-xl font-bold mb-4 flex justify-between">
              <span>Proposal Status:</span>
              <span>{getStatusName(proposal.status || 1)}</span>
            </h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Voting Schedule:</span>
                <div className="text-right">
                  {proposal.openvote && proposal.closevote ? (
                    <>
                      <div className="font-medium">
                        Opens: {new Date(proposal.openvote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(proposal.openvote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                      <div className="font-medium">
                        Closes: {new Date(proposal.closevote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(proposal.closevote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                    </>
                  ) : (
                    <span className="font-medium">Pending Discussion</span>
                  )}
                </div>
              </p>
              <p className="flex justify-between">
                <span>Snapshot Taken:</span>
                <span className="font-medium">
                  {proposal.snapshot ? (
                    isLoadingSnapshot ? (
                      <div className="text-right">Loading...</div>
                    ) : snapshotData?.generated ? (
                      <div className="text-right">
                        {new Date(snapshotData.generated).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(snapshotData.generated).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                    ) : (
                      <div className="text-right">Error loading snapshot</div>
                    )
                  ) : (proposal.openvote && proposal.closevote) ? "Pending Vote Schedule" : "Pending Schedule"}
                </span>
              </p>
            </div>
          </div>

          {!proposal.votesactive ? (
            // Nomination Progress Card
            <div className="card">
              <h4 className="text-xl font-bold mb-4">Nomination Progress</h4>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-6 mb-4">
                <div
                  className="bg-primary h-6 rounded-full transition-all duration-500"
                  style={{ width: `${nominationProgress}%` }}
                ></div>
              </div>
              <p className="text-center font-medium mb-2">
                {nominations.length} of 10 nominations received
              </p>
              <p className="text-center text-sm text-gray-500 mb-4">
                {10 - nominations.length} more nominations needed to start voting
              </p>
              <div className="flex justify-center">
                <button
                  className="button-primary w-4/5"
                  onClick={() => setShowNominatePopup(true)}
                >
                  Nominate Proposal
                </button>
              </div>
            </div>
          ) : (
            // Voting Status Card
            <div className="card">
              <h4 className="text-xl font-bold mb-4">Voting Status</h4>
              <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-6 mb-4 overflow-hidden flex">
                <div
                  className="bg-green-500 h-6 transition-all duration-500"
                  style={{ width: `${yesPercentage}%` }}
                ></div>
                <div
                  className="bg-red-500 h-6 transition-all duration-500"
                  style={{ width: `${noPercentage}%` }}
                ></div>
              </div>
              <div className="grid grid-cols-3 mb-2">
                <p className="text-green-500 font-medium text-left">Yes: {totalYesVotes.toLocaleString()}</p>
                <p className="text-sm font-medium text-center">
                  {totalVotesCounted > 0 ? (
                    yesPercentage > noPercentage ? (
                      <span className="text-green-500">{Math.round(yesPercentage)}% Yes</span>
                    ) : (
                      <span className="text-red-500">{Math.round(noPercentage)}% No</span>
                    )
                  ) : (
                    "No votes yet"
                  )}
                </p>
                <p className="text-red-500 font-medium text-right">No: {totalNoVotes.toLocaleString()}</p>
              </div>
              <p className="text-center text-sm text-gray-500 mb-4">
                Votes Counted: {totalVotesCounted.toLocaleString()}
              </p>
              <div className="flex justify-between gap-4">
                <button
                  className="button-success flex-1"
                  onClick={() => setShowVoteYesPopup(true)}
                >
                  Vote Yes
                </button>
                <button
                  className="button-danger flex-1"
                  onClick={() => setShowVoteNoPopup(true)}
                >
                  Vote No
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="card mb-8">
          <h2 className="text-3xl font-bold mb-3">{proposal.title}</h2>
          <h3 className="text-xl text-gray-600 dark:text-gray-300 mb-6">{proposal.description}</h3>
          <div className="prose dark:prose-invert max-w-none">
            <ReactMarkdown className="proposal-markdown">{proposal.body}</ReactMarkdown>
          </div>
        </div>

        {/* Vote History Card */}
        {(yesVotes.length > 0 || noVotes.length > 0) && (
          <div className="card">
            <h3 className="text-xl font-bold mb-4">Vote History</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th className="px-4 py-3 text-left text-sm font-semibold">Vote Type</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold">From Address</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold">Transaction</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold">Timestamp</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold">Vote Power</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold">Amount Sent</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {[...(Array.isArray(proposal?.yes_votes) ? proposal.yes_votes : []),
                  ...(Array.isArray(proposal?.no_votes) ? proposal.no_votes : [])]
                    .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                    .map((vote) => {
                      // Determine if it's a yes vote by checking if it exists in the yes_votes array
                      const isYesVote = Array.isArray(proposal?.yes_votes) &&
                        proposal.yes_votes.some((v: { hash: string }) => v.hash === vote.hash);

                      return (
                        <tr key={vote.hash}>
                          <td className="px-4 py-3">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${isYesVote
                                ? 'bg-green-100 text-green-800'
                                : 'bg-red-100 text-red-800'
                              }`}>
                              {isYesVote ? 'Yes' : 'No'}
                            </span>
                          </td>
                          <td className="px-4 py-3 text-sm">{shortenString(vote.fromaddress)}</td>
                          <td className="px-4 py-3 text-sm">{shortenString(vote.hash)}</td>
                          <td className="px-4 py-3 text-sm">{formatDate(vote.created)}</td>
                          <td className="px-4 py-3 text-sm text-right">{formatNumber(vote.votescounted)}</td>
                          <td className="px-4 py-3 text-sm text-right">{formatNumber(vote.amountsent)}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Vote modals */}
        {showVoteNoPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 relative overflow-y-auto max-h-[90vh]">
              <button
                onClick={() => {
                  setShowVoteNoPopup(false);
                  setTransactionHash('');
                  setVoteAmount('');
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>

              <h3 className="text-xl font-bold mb-4">Vote No on Proposal</h3>

              <div className="space-y-4">
                {/* Step 1: Send Tokens */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 1: Send Tokens</h4>
                  <p className="text-sm mb-4">Send {process.env.REACT_APP_GOV_TOKEN_TICKER} tokens to the No vote address:</p>

                  <div className="flex justify-center mb-4">
                    <QRCode value={config?.addresses?.no || ''} size={96} />
                  </div>

                  <div className="flex items-center justify-between bg-gray-200 dark:bg-gray-600 p-2 rounded">
                    <code className="text-sm">{config?.addresses?.no}</code>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(config?.addresses?.no || '');
                        toast.success('Address copied!');
                      }}
                      className="text-primary hover:text-primary-dark"
                    >
                      <ClipboardIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Vote Calculator */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Vote Calculator</h4>
                  <p className="text-sm mb-4">Calculate your voting power before sending tokens:</p>

                  <input
                    type="number"
                    value={voteAmount}
                    onChange={(e) => {
                      const newAmount = e.target.value;
                      setVoteAmount(newAmount);
                      calculateVotePower(newAmount);
                    }}
                    min={config?.proposals?.votingFeeMin || 0.1}
                    max={config?.proposals?.votingFeeMax || 100000}
                    step="0.1"
                    placeholder={`Enter amount of ${process.env.REACT_APP_GOV_TOKEN_TICKER} tokens`}
                    className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500 mb-4"
                  />

                  {estimatedVotes !== null && (
                    <div className="text-sm">
                      <p>Calculated Votes: <span className="font-medium">{estimatedVotes}</span></p>
                      <p>Power Level: <span className="font-medium">{powerLevel}</span></p>

                      {/* Power Scale */}
                      <div className="mt-2 space-y-1">
                        <div className="flex space-x-1">
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Piano' ? 'bg-primary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Piano' ? 'bg-primary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Forte' ? 'bg-primary-dark scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Forte' ? 'bg-secondary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Fortissimo' ? 'bg-secondary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                        </div>
                        <div className="flex justify-between text-[10px] text-gray-500">
                          <div className="text-center w-1/5">Piano</div>
                          <div className="text-center w-1/5">Mezzo Piano</div>
                          <div className="text-center w-1/5">Mezzo Forte</div>
                          <div className="text-center w-1/5">Forte</div>
                          <div className="text-center w-1/5">Fortissimo</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Step 2: Enter Transaction Hash */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 2: Enter Transaction Hash</h4>
                  <input
                    type="text"
                    value={transactionHash}
                    onChange={(e) => setTransactionHash(e.target.value)}
                    placeholder="Enter your transaction hash"
                    className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>

                {/* Submit Button */}
                <button
                  onClick={async () => {
                    if (!transactionHash) {
                      toast.error('Please enter a transaction hash');
                      return;
                    }
                    await handleNoVoteSubmit(transactionHash);
                    setShowVoteNoPopup(false);
                    setTransactionHash('');
                    setVoteAmount('');
                  }}
                  disabled={!transactionHash}
                  className="button-primary w-full disabled:opacity-50"
                >
                  Submit Vote
                </button>
              </div>
            </div>
          </div>
        )}
        {showVoteYesPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 relative overflow-y-auto max-h-[90vh]">
              <button
                onClick={() => {
                  setShowVoteYesPopup(false);
                  setTransactionHash('');
                  setVoteAmount('');
                }}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>

              <h3 className="text-xl font-bold mb-4">Vote Yes on Proposal</h3>

              <div className="space-y-4">
                {/* Step 1: Send Tokens */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 1: Send Tokens</h4>
                  <p className="text-sm mb-4">Send {process.env.REACT_APP_GOV_TOKEN_TICKER} tokens to the Yes vote address:</p>

                  <div className="flex justify-center mb-4">
                    <QRCode value={config?.addresses?.yes || ''} size={96} />
                  </div>

                  <div className="flex items-center justify-between bg-gray-200 dark:bg-gray-600 p-2 rounded">
                    <code className="text-sm">{config?.addresses?.yes}</code>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(config?.addresses?.yes || '');
                        toast.success('Address copied!');
                      }}
                      className="text-primary hover:text-primary-dark"
                    >
                      <ClipboardIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Vote Calculator */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Vote Calculator</h4>
                  <p className="text-sm mb-4">Calculate your voting power before sending tokens:</p>

                  <input
                    type="number"
                    value={voteAmount}
                    onChange={(e) => {
                      const newAmount = e.target.value;
                      setVoteAmount(newAmount);
                      calculateVotePower(newAmount);
                    }}
                    min={config?.proposals?.votingFeeMin || 0.1}
                    max={config?.proposals?.votingFeeMax || 100000}
                    step="0.1"
                    placeholder={`Enter amount of ${process.env.REACT_APP_GOV_TOKEN_TICKER} tokens`}
                    className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500 mb-4"
                  />

                  {estimatedVotes !== null && (
                    <div className="text-sm">
                      <p>Calculated Votes: <span className="font-medium">{estimatedVotes}</span></p>
                      <p>Power Level: <span className="font-medium">{powerLevel}</span></p>

                      {/* Power Scale */}
                      <div className="mt-2 space-y-1">
                        <div className="flex space-x-1">
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Piano' ? 'bg-primary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Piano' ? 'bg-primary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Forte' ? 'bg-primary-dark scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Forte' ? 'bg-secondary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          <div className={`h-2 flex-1 rounded ${powerLevel === 'Fortissimo' ? 'bg-secondary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                        </div>
                        <div className="flex justify-between text-[10px] text-gray-500">
                          <div className="text-center w-1/5">Piano</div>
                          <div className="text-center w-1/5">Mezzo Piano</div>
                          <div className="text-center w-1/5">Mezzo Forte</div>
                          <div className="text-center w-1/5">Forte</div>
                          <div className="text-center w-1/5">Fortissimo</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Step 2: Enter Transaction Hash */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 2: Enter Transaction Hash</h4>
                  <input
                    type="text"
                    value={transactionHash}
                    onChange={(e) => setTransactionHash(e.target.value)}
                    placeholder="Enter your transaction hash"
                    className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>

                {/* Submit Button */}
                <button
                  onClick={async () => {
                    if (!transactionHash) {
                      toast.error('Please enter a transaction hash');
                      return;
                    }
                    await handleYesVoteSubmit(transactionHash);
                    setShowVoteYesPopup(false);
                    setTransactionHash('');
                    setVoteAmount('');
                  }}
                  disabled={!transactionHash}
                  className="button-primary w-full disabled:opacity-50"
                >
                  Submit Vote
                </button>
              </div>
            </div>
          </div>
        )}
        {showNominatePopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fadeIn">
            <div className="card p-4 text-center max-w-2xl mx-auto animate-slideUp relative">
              <button
                onClick={() => {
                  setShowNominatePopup(false);
                  setNominationError(null);
                  setIsVerifying(false);
                  setVerificationProgress(null);
                }}
                className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
              </button>
              <h2 className="section-title mb-4">Nominate this Proposal</h2>
              {nominationError ? (
                <>
                  <div className="text-red-500 mb-4">
                    Error verifying your nomination.
                  </div>
                  <ol className="text-left text-gray-600 dark:text-gray-300 mb-6 space-y-2">
                    <li>Remember:</li>
                    <li>- You can only nominate a proposal once.</li>
                    <li>- The ticker and amount must match exactly.</li>
                    <li>- The transaction must be within the last hour.</li>
                    <li></li>
                    <li>Please:</li>
                    <li>1. Save your transaction ID</li>
                    <li>2. Reach out to us on <a href="https://t.me/kaspaDAO" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Telegram</a> or <a href="https://discord.gg/kaspadao" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Discord</a> for support</li>
                  </ol>
                  <div className="flex gap-4">
                    <button
                      className="button-secondary flex-1"
                      onClick={() => {
                        setNominationError(null);
                        setShowNominatePopup(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="button-primary flex-1"
                      onClick={() => {
                        setNominationError(null);
                        setIsVerifying(false);
                        setVerificationProgress(null);
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                </>
              ) : isVerifying ? (
                <div className="mb-4">
                  <div className="mb-4">
                    <div className="text-lg font-semibold mb-2">
                      Verifying your nomination...
                    </div>
                    {verificationProgress && (
                      <div className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                        Attempt # {verificationProgress.attempt} of {verificationProgress.maxAttempts}
                      </div>
                    )}
                  </div>
                  <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mb-4">
                    {verificationProgress && (
                      <div
                        className="bg-primary h-2.5 rounded-full transition-all duration-300"
                        style={{
                          width: `${(verificationProgress.attempt / verificationProgress.maxAttempts) * 100}%`
                        }}
                      />
                    )}
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400 space-y-2">
                    <p>This process may take up to 90 seconds.</p>
                    <p>We are analyzing the transaction history.</p>
                    <p>Please keep this window open.</p>
                  </div>
                </div>
              ) : (
                <>
                  <p className="text-gray-600 dark:text-gray-300 mb-6">
                    To nominate this proposal:
                  </p>
                  <ol className="text-left text-gray-600 dark:text-gray-300 mb-6 space-y-3">
                    <li>1. Send exactly <span className="font-semibold">{nominationFee} {process.env.REACT_APP_GOV_TOKEN_TICKER}</span> to the wallet address below</li>
                    <li>2. After sending, click "Verify Nomination" to confirm your transaction</li>
                    <li>3. Keep this window open during verification (up to 90 seconds)</li>
                  </ol>
                  {proposal.proposal_wallets_proposals_walletToproposal_wallets?.address && (
                    <div className="flex justify-center mb-6">
                      <QRCode value={proposal.proposal_wallets_proposals_walletToproposal_wallets.address} size={128} />
                    </div>
                  )}
                  <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg mb-6">
                    <p className="font-medium text-center break-all">
                      {proposal.proposal_wallets_proposals_walletToproposal_wallets?.address || 'No wallet address available'}
                    </p>
                  </div>
                  <div className="flex flex-col gap-4">
                    {proposal.proposal_wallets_proposals_walletToproposal_wallets?.address && (
                      <div className="flex gap-4">
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(proposal.proposal_wallets_proposals_walletToproposal_wallets!.address);
                            // You might want to add a toast notification here
                          }}
                          className="button-secondary flex-1"
                        >
                          Copy Wallet Address
                        </button>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(nominationFee?.toString() || '');
                            // You might want to add a toast notification here
                          }}
                          className="button-secondary flex-1"
                        >
                          Copy Fee Amount
                        </button>
                      </div>
                    )}
                    <button
                      className="button-primary"
                      onClick={async () => {
                        try {
                          if (!nominationFee || !id) {
                            setNominationError('Missing required data for verification');
                            return;
                          }

                          setIsVerifying(true);
                          setNominationError(null);

                          const response = await verifyNominationTransaction(
                            {
                              fee: nominationFee,
                              proposalId: Number(id)
                            },
                            (progress) => {
                              setVerificationProgress(progress);
                            }
                          );

                          if (response.status === 'completed') {
                            // Handle immediate success
                            const updatedNominations = await getProposalNominations(Number(id));
                            setNominations(updatedNominations);
                            setIsVerifying(false);
                            setVerificationProgress(null);
                            setShowNominatePopup(false);
                            setNominationError(null);
                            toast.success(
                              `Nomination verified successfully! Transaction from ${response.nomination?.fromaddress || 'unknown address'}`,
                              { duration: 30000 }
                            );
                            return;
                          }

                          if (response.status === 'pending' && response.verificationId) {
                            // Start polling for status
                            let attempts = 0;
                            const maxAttempts = 30;
                            const pollInterval = setInterval(async () => {
                              try {
                                const statusResponse = await getNominationVerificationStatus(Number(id), response.verificationId);
                                if (statusResponse.status === 'completed') {
                                  clearInterval(pollInterval);
                                  const updatedNominations = await getProposalNominations(Number(id));
                                  setNominations(updatedNominations);
                                  setIsVerifying(false);
                                  setVerificationProgress(null);
                                  setShowNominatePopup(false);
                                  setNominationError(null);
                                  toast.success(
                                    `Nomination verified successfully! Transaction from ${statusResponse.nomination?.fromaddress || 'unknown address'}`,
                                    { duration: 30000 }
                                  );
                                } else {
                                  attempts++;
                                  setVerificationProgress({ attempt: attempts, maxAttempts });
                                  if (attempts >= maxAttempts) {
                                    clearInterval(pollInterval);
                                    setNominationError('Verification timed out. Please save your transaction ID and reach out to us on Telegram or Discord for support.');
                                    setIsVerifying(false);
                                    setVerificationProgress(null);
                                  }
                                }
                              } catch (error) {
                                clearInterval(pollInterval);
                                console.error('Error checking verification status:', error);
                                setNominationError('Error checking verification status. Please save your transaction ID and reach out to us on Telegram or Discord for support.');
                                setIsVerifying(false);
                                setVerificationProgress(null);
                              }
                            }, 3000);
                          }
                        } catch (error: any) {
                          console.error('Error verifying nomination:', error);
                          setNominationError(
                            'Verification failed. Please save your transaction ID and reach out to us on Telegram or Discord for support.'
                          );
                          setIsVerifying(false);
                          setVerificationProgress(null);
                        }
                      }}
                    >
                      Verify Nomination
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {/* Edit Modal */}
        {isEditModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fadeIn">
            <div className="card p-4 text-center max-w-2xl mx-auto animate-slideUp">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => setIsEditModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <h2 className="section-title mb-4">Edit Proposal Verification</h2>

              <div className="space-y-4">
                {/* Step 1: Send Tokens */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 1: Send Tokens</h4>
                  <p className="text-sm mb-4">
                    Send exactly{' '}
                    <span className="font-mono font-medium">
                      {editFee} {editTokenTicker}
                    </span>{' '}
                    to verify ownership:
                  </p>

                  <div className="flex justify-center mb-4">
                    <QRCode
                      value={proposal?.proposal_wallets_proposals_walletToproposal_wallets?.address || ''}
                      size={128}
                      className="p-2 bg-white rounded-lg"
                    />
                  </div>

                  <div className="bg-gray-200 dark:bg-gray-600 p-2 rounded mb-4">
                    <p className="font-mono text-sm break-all">
                      {proposal?.proposal_wallets_proposals_walletToproposal_wallets?.address}
                    </p>
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(proposal?.proposal_wallets_proposals_walletToproposal_wallets?.address || '');
                        toast.success('Address copied to clipboard');
                      }}
                      className="button-secondary flex items-center justify-center"
                    >
                      <ClipboardIcon className="h-4 w-4 mr-2" />
                      Copy Address
                    </button>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(`${editFee}`);
                        toast.success('Amount copied to clipboard');
                      }}
                      className="button-secondary flex items-center justify-center"
                    >
                      <ClipboardIcon className="h-4 w-4 mr-2" />
                      Copy Amount
                    </button>
                  </div>
                </div>

                {/* Warning Message */}
                <div className="bg-yellow-50 dark:bg-yellow-900/30 border-l-4 border-yellow-400 p-4">
                  <div className="flex">
                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700 dark:text-yellow-200">
                        Important: After verification, you will receive an edit link. This link will work for anyone who has it until voting begins. Keep it secure and do not share it.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Step 2: Enter Transaction Hash */}
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-semibold mb-2">Step 2: Enter Transaction Hash</h4>
                  <input
                    type="text"
                    value={editTransactionHash}
                    onChange={(e) => setEditTransactionHash(e.target.value)}
                    placeholder="Enter your transaction hash"
                    className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>

                {/* Action Buttons */}
                <div className="flex gap-4">
                  <button
                    onClick={() => setIsEditModalOpen(false)}
                    className="button-secondary flex-1"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleVerifyEdit}
                    disabled={!editTransactionHash || isEditLoading}
                    className="button-primary flex-1 disabled:opacity-50"
                  >
                    {isEditLoading ? (
                      <div className="flex items-center justify-center">
                        <ArrowPathIcon className="animate-spin -ml-1 mr-2 h-5 w-5" />
                        Verifying...
                      </div>
                    ) : (
                      'Verify & Continue'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalDetail;
