import { ofetch } from 'ofetch';
import {
  Proposal,
  ProposalUpdate,
  Election,
  Status,
  ProposalType,
  ProposalVote,
  CandidateVote,
  CandidateNomination,
  CandidateWallet,
  ElectionType,
  ElectionStatus,
  ElectionPosition,
  UpdateElection,
  NewElection,
  Candidate,
  NewCandidate,
  UpdateCandidate,
  BurnTransaction,
  GasDropTransaction,
  ProposalSnapshot,
  ProposalQueryParams,
  Config,
  TreasuryWallet,
  PrimaryElection
} from '../types';

const api = ofetch.create({
  baseURL: 'https://govapi.kaspadao.org/api',
});

// System Routes
export const getConfig = async (): Promise<Config> => {
  return await api('/config', {
    method: 'GET',
  });
};

export const getActiveProposalCount = async (): Promise<number> => {
  return await api('/active/proposal/count', {
    method: 'GET',
  });
};

export const getActiveElectionCount = async (): Promise<number> => {
  return await api('/active/election/count', {
    method: 'GET',
  });
};

export const getTreasuryWallets = async (): Promise<TreasuryWallet[]> => {
  return await api('/treasury/wallets', {
    method: 'GET',
  });
};

export const getTreasuryWalletTransactions = async (address: string): Promise<any[]> => {
  return await api(`/treasury/wallet/${address}/transactions`, {
    method: 'GET',
  });
};

// Proposal Management
export const getProposals = async (params: ProposalQueryParams = {}): Promise<Proposal[]> => {
  return await api('/proposals', {
    method: 'GET',
    params,
  });
};

export const createProposal = async (proposal: Proposal): Promise<Proposal> => {
  return await api('/proposal', {
    method: 'POST',
    body: proposal,
  });
};

export const getProposalById = async (proposalId: number): Promise<Proposal> => {
  return await api(`/proposal/${proposalId}`, {
    method: 'GET',
  });
};

export const updateProposal = async (proposalId: number, data: ProposalUpdate): Promise<Proposal> => {
  return await api(`/proposal/${proposalId}`, {
    method: 'PUT',
    body: data,
  });
};

// Proposal Types
export const getProposalTypes = async (): Promise<ProposalType[]> => {
  return await api('/proposal/types', {
    method: 'GET',
  });
};

export const createProposalType = async (type: ProposalType): Promise<ProposalType> => {
  return await api('/proposal/types', {
    method: 'POST',
    body: type,
  });
};

export const updateProposalType = async (typeId: number, type: ProposalType): Promise<ProposalType> => {
  return await api(`/proposal/types/${typeId}`, {
    method: 'PUT',
    body: type,
  });
};

export const deleteProposalType = async (typeId: number): Promise<void> => {
  await api(`/proposal/types/${typeId}`, {
    method: 'DELETE',
  });
};

// Proposal Statuses
export const getProposalStatuses = async (): Promise<Status[]> => {
  return await api('/proposal/statuses', {
    method: 'GET',
  });
};

export const createProposalStatus = async (status: Status): Promise<Status> => {
  return await api('/proposal/statuses', {
    method: 'POST',
    body: status,
  });
};

export const updateProposalStatus = async (statusId: number, status: Status): Promise<Status> => {
  return await api(`/proposal/statuses/${statusId}`, {
    method: 'PUT',
    body: status,
  });
};

export const deleteProposalStatus = async (statusId: number): Promise<void> => {
  await api(`/proposal/statuses/${statusId}`, {
    method: 'DELETE',
  });
};

// Proposal Voting
export const getProposalVotes = async (proposalId: number): Promise<ProposalVote[]> => {
  return await api(`/proposal/${proposalId}/votes`, {
    method: 'GET',
  });
};

export const getProposalYesVotes = async (): Promise<ProposalVote[]> => {
  return await api('/proposal/votes/yes', {
    method: 'GET',
  });
};

export async function submitProposalYesVote(proposal_id: number, transaction_hash: string): Promise<any> {
  try {
    const response = await api('/proposal/votes/yes', {
      method: 'POST',
      body: {
        proposal_id,
        transaction_hash
      }
    });
    return response;
  } catch (error) {
    console.error('Error submitting yes vote:', error);
    throw error;
  }
}

export const getProposalNoVotes = async (): Promise<ProposalVote[]> => {
  return await api('/proposal/votes/no', {
    method: 'GET',
  });
};

export async function submitProposalNoVote(proposal_id: number, transaction_hash: string): Promise<any> {
  try {
    const response = await api('/proposal/votes/no', {
      method: 'POST',
      body: {
        proposal_id,
        transaction_hash
      }
    });
    return response;
  } catch (error) {
    console.error('Error submitting no vote:', error);
    throw error;
  }
}

// Proposal Nominations
export const getProposalNominations = async (proposalId: number): Promise<any[]> => {
  if (!proposalId) {
    console.warn('No proposal ID provided for nominations fetch');
    return [];
  }

  try {
    const response = await api(`/proposal/${proposalId}/nominations`, {
      method: 'GET',
    });
    return response || [];
  } catch (error) {
    // Don't log 400 errors as they're expected when a proposal doesn't exist
    if (!(error instanceof Error && error.message.includes('400'))) {
      console.error('Error fetching nominations:', error);
    }
    return [];
  }
};

export const getProposalNominationCount = async (proposalId: number): Promise<number> => {
  return await api(`/proposal/${proposalId}/nominations/count`, {
    method: 'GET',
  });
};

export const getProposalNominationFee = async (): Promise<{ fee: number }> => {
  return await api('/proposal/nomination/fee', {
    method: 'GET',
  });
};

export const getProposalEditFee = async (): Promise<{ fee: number }> => {
  return await api('/proposal/edit/fee', {
    method: 'GET',
  });
};

export const getNominationVerificationStatus = async (proposalId: number, verificationId: string): Promise<any> => {
  return await api(`/proposal/${proposalId}/nominations/status`, {
    method: 'GET',
    params: { verificationId }
  });
};

export const verifyNominationTransaction = async (
  data: { fee: number; proposalId: number },
  onProgress?: (progress: { attempt: number; maxAttempts: number }) => void
): Promise<any> => {
  try {
    // Start the verification process
    const response = await api('/proposal/nomination/create', {
      method: 'POST',
      body: {
        fee: Number(data.fee),
        proposalId: Number(data.proposalId)
      }
    });

    if (response.status === 'pending' && response.verificationId) {
      const maxAttempts = 30; // 90 seconds total with 3-second intervals
      let attempt = 0;

      while (attempt < maxAttempts) {
        // Update progress callback
        if (onProgress) {
          onProgress({ attempt: attempt + 1, maxAttempts });
        }

        // Check verification status
        try {
          const verificationStatus = await getNominationVerificationStatus(data.proposalId, response.verificationId);

          if (verificationStatus.status === 'completed') {
            return {
              status: 'completed',
              nomination: verificationStatus.nomination,
              proposal: verificationStatus.proposal // Include updated proposal data
            };
          }
        } catch (error: any) {
          // If error is not 404 (not found), throw it
          if (!error.message?.includes('404')) {
            throw error;
          }
        }

        // Wait for the specified retry interval
        await new Promise(resolve => setTimeout(resolve, response.retryAfter * 1000));
        attempt++;
      }

      throw new Error('Verification timed out');
    }

    return response;
  } catch (error: any) {
    console.error('Error verifying nomination:', error);
    throw error;
  }
};

// Elections
export const getElections = async (): Promise<Election[]> => {
  return await api('/elections', {
    method: 'GET',
  });
};

export const getElectionPrimaries = async (): Promise<PrimaryElection[]> => {
  const response = await api('/elections/primaries', {
    method: 'GET',
  });
  return response.map((primary: any) => ({
    ...primary,
    candidates: primary.candidates || [],
    parent_election_id: primary.parent_election_id || 0
  }));
};

// Election Types
export const getElectionTypes = async (): Promise<ElectionType[]> => {
  return await api('/election/types', {
    method: 'GET',
  });
};

// Election Statuses
export const getElectionStatuses = async (): Promise<ElectionStatus[]> => {
  return await api('/election/statuses', {
    method: 'GET',
  });
};

// Election Positions
export const getElectionPositions = async (): Promise<ElectionPosition[]> => {
  return await api('/election/positions', {
    method: 'GET',
  });
};

// Create Election
export const createElection = async (election: NewElection): Promise<Election> => {
  return await api('/election', {
    method: 'POST',
    body: election,
  });
};

export const deleteElection = async (electionId: number): Promise<void> => {
  await api(`/election/${electionId}`, {
    method: 'DELETE',
  });
};

// Candidate Management
export const submitCandidateVote = async (vote: CandidateVote): Promise<CandidateVote> => {
  return await api('/election/candidate/votes', {
    method: 'POST',
    body: vote,
  });
};

export const getCandidateVotes = async (candidateId: number): Promise<CandidateVote[]> => {
  return await api(`/election/candidate/${candidateId}/votes`, {
    method: 'GET',
  });
};

export const getCandidateWallets = async (): Promise<CandidateWallet[]> => {
  return await api('/election/candidate/wallets', {
    method: 'GET',
  });
};

export const createCandidateWallet = async (wallet: CandidateWallet): Promise<CandidateWallet> => {
  return await api('/election/candidate/wallets', {
    method: 'POST',
    body: wallet,
  });
};

export const updateCandidateWallet = async (walletId: number, wallet: CandidateWallet): Promise<CandidateWallet> => {
  return await api(`/election/candidate/wallets/${walletId}`, {
    method: 'PUT',
    body: wallet,
  });
};

export const deleteCandidateWallet = async (walletId: number): Promise<void> => {
  await api(`/election/candidate/wallets/${walletId}`, {
    method: 'DELETE',
  });
};

export const getCandidateNominations = async (): Promise<CandidateNomination[]> => {
  return await api('/election/candidate/nominations', {
    method: 'GET',
  });
};

export const submitCandidateNomination = async (nomination: CandidateNomination): Promise<CandidateNomination> => {
  return await api('/election/candidate/nominations', {
    method: 'POST',
    body: nomination,
  });
};

export const updateCandidateNomination = async (nominationId: number, nomination: CandidateNomination): Promise<CandidateNomination> => {
  return await api(`/election/candidate/nominations/${nominationId}`, {
    method: 'PUT',
    body: nomination,
  });
};

export const deleteCandidateNomination = async (nominationId: number): Promise<void> => {
  await api(`/election/candidate/nominations/${nominationId}`, {
    method: 'DELETE',
  });
};

export const getCandidateNominationFee = async (): Promise<{ nominationFeeUsd: number }> => {
  return await api('/election/nomination/fee', {
    method: 'GET',
  });
};

export const createDraftProposal = async (): Promise<Proposal> => {
  return await api('/proposal', {
    method: 'POST',
    body: {
      title: "A draft proposal, please replace with the title of your proposal.",
      description: "Please replace this text with a short description of your proposal.",
      body: '',
      type: 1,
      status: 1, // Draft status
      submitted: new Date().toISOString(),
      reviewed: false,
      approved: false,
      passed: false,
      votesactive: false
    },
  });
};

// Get Election By Id
export const getElectionById = async (id: number): Promise<Election | PrimaryElection> => {
  const response = await api(`/election/${id}`, {
    method: 'GET',
  });

  // Check if this is a primary election by looking for the candidates field
  if ('candidates' in response) {
    return {
      ...response,
      candidates: response.candidates || [],
      parent_election_id: response.parent_election_id || 0
    } as PrimaryElection;
  }

  return response as Election;
};

// Update Election
export const updateElection = async (id: number, election: UpdateElection): Promise<Election> => {
  return await api(`/election/${id}`, {
    method: 'PUT',
    body: election,
  });
};

// Create Election Position
export const createElectionPosition = async (position: { title: string; description: string }): Promise<ElectionPosition> => {
  return await api('/election/positions', {
    method: 'POST',
    body: position,
  });
};

// Delete Election Position
export const deleteElectionPosition = async (id: number): Promise<void> => {
  await api(`/election/positions/${id}`, {
    method: 'DELETE',
  });
};

// Update Election Position
export const updateElectionPosition = async (id: number, position: { title: string; description: string }): Promise<ElectionPosition> => {
  return await api(`/election/positions/${id}`, {
    method: 'PUT',
    body: position,
  });
};

// Candidate functions
export const getCandidates = async (): Promise<Candidate[]> => {
  try {
    const response = await api('/election/candidates', {
      method: 'GET'
    });
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error fetching candidates:', error);
    return [];
  }
};

export const getCandidateById = async (id: number): Promise<Candidate> => {
  return await api(`/election/candidates/${id}`, {
    method: 'GET'
  });
};

export const createCandidate = async (candidate: NewCandidate): Promise<Candidate> => {
  return await api('/election/candidates', {
    method: 'POST',
    body: {
      name: candidate.name,
      twitter: candidate.twitter || null,
      discord: candidate.discord || null,
      telegram: candidate.telegram || null,
      type: candidate.type,
      status: candidate.status,
      created: new Date().toISOString(),
      data: null,
      wallet: null,
      nominations: null
    },
  });
};

export const updateCandidate = async (id: number, candidate: UpdateCandidate): Promise<Candidate> => {
  return await api(`/election/candidates/${id}`, {
    method: 'PUT',
    body: candidate,
  });
};

export const deleteCandidate = async (id: number): Promise<void> => {
  await api(`/election/candidates/${id}`, {
    method: 'DELETE',
  });
};

export const getCandidateStatuses = async (): Promise<Status[]> => {
  return await api('/election/candidates/statuses', {
    method: 'GET',
  });
};

export const getCandidateTypes = async (): Promise<Status[]> => {
  return await api('/election/candidates/types', {
    method: 'GET',
  });
};

// Wallet Operations
export const burnKRC20 = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/krc20', {
    method: 'POST',
    body: transaction,
  });
};

export const burnKaspa = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/kaspa', {
    method: 'POST',
    body: transaction,
  });
};

export const returnGov = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/return-gov', {
    method: 'POST',
    body: transaction,
  });
};

export const dropGas = async (transaction: GasDropTransaction): Promise<void> => {
  await api('/burn/drop-gas', {
    method: 'POST',
    body: transaction,
  });
};

// Enhanced Candidate functions
export const getCandidateWithDetails = async (id: number): Promise<Candidate> => {
  return await api(`/election/candidates/${id}`, {
    method: 'GET',
    params: { include: 'wallet,nominations,elections' }
  });
};

export const getCandidateWallet = async (candidateId: number): Promise<CandidateWallet> => {
  return await api(`/election/candidates/${candidateId}/wallet`, {
    method: 'GET'
  });
};

// Type guard to check if an election is a regular election
function isRegularElection(election: Election | PrimaryElection): election is Election {
  return !('candidates' in election);
}

export const assignCandidateToElection = async (electionId: number, candidateId: number, isFirstCandidate: boolean): Promise<Election> => {
  try {
    console.log('Fetching election:', electionId);
    const election = await getElectionById(electionId);
    if (!election) {
      throw new Error('Election not found');
    }

    // Ensure this is a regular election
    if (!isRegularElection(election)) {
      throw new Error('Cannot assign candidates to a primary election');
    }

    console.log('Current election state:', election);

    // Now TypeScript knows this is a regular Election
    const updateData = {
      ...election,
      firstcandidate: isFirstCandidate ? candidateId : election.firstcandidate,
      secondcandidate: !isFirstCandidate ? candidateId : election.secondcandidate,
      // Remove relationship fields that shouldn't be sent in the update
      election_types: undefined,
      election_positions: undefined,
      election_candidates_elections_firstcandidateToelection_candidates: undefined,
      election_candidates_elections_secondcandidateToelection_candidates: undefined,
      election_statuses: undefined,
      election_snapshots: undefined
    };

    console.log('Updating election with:', updateData);
    return await api(`/election/${electionId}`, {
      method: 'PUT',
      body: updateData
    });
  } catch (error) {
    console.error('Error in assignCandidateToElection:', error);
    throw error;
  }
};

export const removeCandidateFromElection = async (electionId: number, candidateId: number): Promise<void> => {
  try {
    console.log('Fetching election:', electionId);
    const election = await getElectionById(electionId);
    if (!election) {
      throw new Error('Election not found');
    }

    // Ensure this is a regular election
    if (!isRegularElection(election)) {
      throw new Error('Cannot remove candidates from a primary election');
    }

    console.log('Current election state:', election);

    // Now TypeScript knows this is a regular Election
    const updateData = {
      ...election,
      firstcandidate: election.firstcandidate === candidateId ? null : election.firstcandidate,
      secondcandidate: election.secondcandidate === candidateId ? null : election.secondcandidate,
      // Remove relationship fields that shouldn't be sent in the update
      election_types: undefined,
      election_positions: undefined,
      election_candidates_elections_firstcandidateToelection_candidates: undefined,
      election_candidates_elections_secondcandidateToelection_candidates: undefined,
      election_statuses: undefined,
      election_snapshots: undefined
    };

    console.log('Updating election with:', updateData);
    await api(`/election/${electionId}`, {
      method: 'PUT',
      body: updateData
    });
  } catch (error) {
    console.error('Error in removeCandidateFromElection:', error);
    throw error;
  }
};

export const getCandidatesForElection = async (electionId: number): Promise<Candidate[]> => {
  return await api(`/election/${electionId}/candidates`, {
    method: 'GET'
  });
};

// Snapshot functions
export async function getProposalSnapshots(): Promise<ProposalSnapshot[]> {
  try {
    const response = await api('/proposal/snapshots', {
      method: 'GET'
    });
    return response || [];
  } catch (error) {
    console.error('Error fetching proposal snapshots:', error);
    return [];
  }
}

export const createSnapshot = async (entityType: 'proposal' | 'election', entityId: number): Promise<ProposalSnapshot> => {
  return await api('/snapshot', {
    method: 'POST',
    body: { entityType, entityId }
  });
};

export const getSnapshotById = async (id: number): Promise<ProposalSnapshot> => {
  try {
    const response = await api(`/snapshot/${id}`, {
      method: 'GET'
    });
    return response;
  } catch (error) {
    console.error('Error fetching snapshot by ID:', error);
    throw error;
  }
};

export const getElectionCandidates = async (): Promise<Candidate[]> => {
  try {
    const response = await api('/election/candidates', {
      method: 'GET'
    });
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error fetching election candidates:', error);
    return [];
  }
};

export const verifyProposalEdit = async (proposalId: number, transactionHash: string, fee: number) => {
  const response = await api(`/proposal/${proposalId}/verify-edit`, {
    method: 'POST',
    body: {
      transactionHash,
      fee
    }
  });
  return response;
};

export const submitProposal = async (proposalData: Partial<Proposal>) => {
  const response = await api('/proposal', {
    method: 'POST',
    body: proposalData
  });
  return response;
};

export const modifyProposal = async (proposalId: number, proposalData: Partial<Proposal>) => {
  const response = await api(`/proposal/${proposalId}`, {
    method: 'PUT',
    body: proposalData
  });
  return response;
};

// Primary Election Management
export const getPrimaryElectionById = async (id: number): Promise<PrimaryElection> => {
  return await api(`/election/${id}/primary`, {
    method: 'GET',
  });
};

interface CandidateSubmissionResponse {
  id: number;
  name: string;
  description: string;
  twitter?: string;
  discord?: string;
  telegram?: string;
  type: number;
  status: number;
  created: string;
  wallet?: string;
}

// Generate a wallet for a new candidate
export const generateCandidateWallet = async (): Promise<{ address: string; verificationId: string }> => {
  return await api('/election/candidate/wallet/generate', {
    method: 'POST'
  });
};

// Submit a new candidate with verification
export const submitCandidate = async (data: {
  name: string;
  description: string;
  twitter?: string;
  discord?: string;
  telegram?: string;
  primaryId: number;
  verificationId: string;
}): Promise<CandidateSubmissionResponse> => {
  return await api('/election/candidates', {
    method: 'POST',
    body: data
  });
};

// Get candidate submission fee
export const getCandidateSubmissionFee = async (): Promise<{ fee: number }> => {
  const response = await api('/election/nomination/fee', {
    method: 'GET'
  });
  return response;
};
