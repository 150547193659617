import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { getProposalById, modifyProposal, getProposalTypes, getProposalNominations } from '../services/apiService';
import { ProposalType } from '../types';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { toast } from 'react-hot-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Proposal } from '../types';

export default function EditProposal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [body, setBody] = useState('');
  const [type, setType] = useState('');
  const [proposal, setProposal] = useState<Proposal | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [proposalTypes, setProposalTypes] = useState<ProposalType[]>([]);
  const [isVerified, setIsVerified] = useState(false);
  const [showConfirmLeave, setShowConfirmLeave] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate('/proposals');
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [proposalResponse, typesResponse] = await Promise.all([
          getProposalById(Number(id)),
          getProposalTypes()
        ]);
        
        if (proposalResponse) {
          setProposal(proposalResponse);
          setTitle(proposalResponse.title || '');
          setDescription(proposalResponse.description || '');
          setBody(proposalResponse.body || '');
          setType(proposalResponse.type?.toString() || '');
        }
        
        if (typesResponse) {
          setProposalTypes(typesResponse);
        }
      } catch (error) {
        console.error('Error fetching proposal:', error);
        toast.error('Failed to load proposal');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  // Separate useEffect for checking changes
  useEffect(() => {
    const checkChanges = () => {
      if (!proposal) return;
      
      const hasUnsavedChanges = 
        title !== proposal.title ||
        description !== proposal.description ||
        body !== proposal.body ||
        type !== proposal.type?.toString();
      
      setHasChanges(hasUnsavedChanges);
    };

    checkChanges();
  }, [title, description, body, type, proposal]);

  useEffect(() => {
    const checkVerification = async () => {
      try {
        // Get verification hash from URL params
        const urlParams = new URLSearchParams(window.location.search);
        const verificationHash = urlParams.get('verificationHash');

        if (!id || !verificationHash) {
          toast.error('Missing required verification parameters');
          navigate('/proposals');
          return;
        }

        // Get the proposal's nominations
        const nominations = await getProposalNominations(parseInt(id));
        if (!nominations || nominations.length === 0) {
          toast.error('No nominations found for this proposal');
          navigate('/proposals');
          return;
        }

        // Find the nomination with the matching hash
        const verifiedNomination = nominations.find(nom => nom.hash === verificationHash);
        if (!verifiedNomination) {
          toast.error('Invalid verification hash');
          navigate('/proposals');
          return;
        }

        // Find the oldest nomination to compare addresses
        const oldestNomination = nominations.sort((a, b) => {
          const dateA = a.created ? new Date(a.created).getTime() : 0;
          const dateB = b.created ? new Date(b.created).getTime() : 0;
          return dateA - dateB;
        })[0];

        // Verify the addresses match
        if (verifiedNomination.fromaddress?.toLowerCase() !== oldestNomination.fromaddress?.toLowerCase()) {
          toast.error('Only the original nominator can edit the proposal');
          navigate('/proposals');
          return;
        }

        setIsVerified(true);
      } catch (error) {
        console.error('Error checking verification:', error);
        toast.error('Failed to verify edit access');
        navigate('/proposals');
      }
    };

    checkVerification();
  }, [id, navigate]);

  // Keep the beforeunload event listener in a separate useEffect
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasChanges]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !description || !body || !type) {
      toast.error('All fields are required');
      return;
    }

    const proposalData = {
      title,
      description,
      body,
      type: parseInt(type)
    };

    try {
      setIsSubmitting(true);
      await modifyProposal(parseInt(id!), proposalData);
      setHasChanges(false);
      toast.success('Proposal updated successfully');
      navigate(`/proposals/${id}`);
    } catch (error: any) {
      console.error('Error updating proposal:', error);
      toast.error(error.response?.data?.error || 'Failed to update proposal');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (hasChanges) {
      setShowConfirmLeave(true);
    } else {
      navigate(`/proposals/${id}`);
    }
  };

  if (isLoading) {
    return (
      <div className="page-container">
        <Navbar />
        <div className="content-container">
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="animate-pulse text-gray-600 dark:text-gray-300">
              Loading...
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!isVerified) {
    return (
      <div className="page-container">
        <Navbar />
        <div className="content-container">
          <div className="card p-4 text-center">
            <p>Verifying edit access...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        <div className="card p-4 max-w-4xl mx-auto">
          <h2 className="section-title mb-6">Edit Proposal</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="form-label">Type</label>
              <select 
                value={type} 
                onChange={(e) => setType(e.target.value)} 
                className="select-field" 
                required
                disabled={isSubmitting}
              >
                <option value="">Select a type</option>
                {proposalTypes.map((proposalType) => (
                  <option key={proposalType.id} value={proposalType.id}>
                    {proposalType.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="form-label">Title</label>
              <input 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                className="input-field" 
                required 
                disabled={isSubmitting}
              />
            </div>
            <div>
              <label className="form-label">Subtitle</label>
              <input 
                type="text" 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
                className="input-field" 
                required 
                disabled={isSubmitting}
              />
            </div>
            <div>
              <label className="form-label">Proposal Body</label>
              <div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
                <MarkdownEditor
                  value={body}
                  style={{ height: '400px' }}
                  renderHTML={(text) => text}
                  onChange={({ text }) => setBody(text)}
                  view={{ menu: true, md: true, html: false }}
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                    },
                    canView: {
                      menu: true,
                      md: true,
                      html: false,
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  readOnly={isSubmitting}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleCancel}
                className="button-secondary"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="button-primary" 
                disabled={isSubmitting || !hasChanges}
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Updating...
                  </span>
                ) : (
                  'Update Proposal'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Confirm Leave Modal */}
      {showConfirmLeave && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6 relative">
            <button
              onClick={() => setShowConfirmLeave(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h3 className="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
              Unsaved Changes
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              You have unsaved changes. Are you sure you want to leave?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmLeave(false)}
                className="button-secondary"
              >
                Stay
              </button>
              <button
                onClick={() => navigate(`/proposals/${id}`)}
                className="button-primary"
              >
                Leave
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 