import React, { useState, useEffect } from 'react';
import { getProposals, getProposalStatuses, updateProposal } from '../../services/apiService';
import { Proposal, Status, ProposalSnapshot } from '../../types';
import ManagementProposalsTable from './proposals/ManagementProposalsTable';
import ProposalActions from './proposals/ProposalActions';
import ProposalSnapshots from './proposals/ProposalSnapshots';
import ProposalWalletOperations from './proposals/ProposalWalletOperations';

interface ProposalsTabProps {
  updateMessage: string | null;
  setUpdateMessage: (message: string | null) => void;
}

const ProposalsTab: React.FC<ProposalsTabProps> = ({ updateMessage, setUpdateMessage }) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [activeAction, setActiveAction] = useState<string>('manage');
  const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(null);
  const [selectedStatusId, setSelectedStatusId] = useState<number | null>(null);
  const [openVoteDate, setOpenVoteDate] = useState<Date | null>(null);
  const [closeVoteDate, setCloseVoteDate] = useState<Date | null>(null);
  const [approveRejectChoice, setApproveRejectChoice] = useState<boolean>(true);
  const [proposalSnapshots, setProposalSnapshots] = useState<ProposalSnapshot[]>([]);
  const [isCreatingSnapshot, setIsCreatingSnapshot] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [proposalData, statusData] = await Promise.all([
          getProposals(),
          getProposalStatuses()
        ]);
        setProposals(proposalData);
        setStatuses(statusData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setUpdateMessage('Failed to load proposals or statuses');
      }
    };

    fetchData();
  }, [setUpdateMessage]);

  // Reset selected proposal when action changes
  useEffect(() => {
    setSelectedProposal(null);
    setSelectedStatusId(null);
    setOpenVoteDate(null);
    setCloseVoteDate(null);
    setUpdateMessage(null);
  }, [activeAction, setUpdateMessage]);

  const handleProposalSelect = (proposal: Proposal) => {
    setSelectedProposal(proposal);
  };

  const handleApproveReject = async (approved: boolean) => {
    if (selectedProposal?.id && selectedStatusId) {
      try {
        await updateProposal(selectedProposal.id, {
          ...selectedProposal,
          status: selectedStatusId,
          reviewed: true,
          approved
        });
        
        // Refresh proposals
        const updatedProposals = await getProposals();
        setProposals(updatedProposals);
        
        setUpdateMessage(`Proposal successfully ${approved ? 'approved' : 'rejected'}`);
      } catch (error) {
        console.error('Error updating proposal:', error);
        setUpdateMessage('Failed to update proposal');
      }
    }
  };

  const handleStatusUpdate = async () => {
    if (selectedProposal?.id && selectedStatusId) {
      try {
        await updateProposal(selectedProposal.id, {
          ...selectedProposal,
          status: selectedStatusId
        });
        
        // Refresh proposals
        const updatedProposals = await getProposals();
        setProposals(updatedProposals);
        
        setUpdateMessage('Status updated successfully');
      } catch (error) {
        console.error('Error updating status:', error);
        setUpdateMessage('Failed to update status');
      }
    }
  };

  const handleSchedule = async () => {
    if (!selectedProposal?.id || !openVoteDate || !closeVoteDate || !selectedStatusId) {
      setUpdateMessage('Please fill in all required fields');
      return;
    }

    // Convert dates to UTC
    const utcOpenVote = new Date(openVoteDate.getTime() - openVoteDate.getTimezoneOffset() * 60000);
    const utcCloseVote = new Date(closeVoteDate.getTime() - closeVoteDate.getTimezoneOffset() * 60000);

    // Validate dates
    const votingDurationHours = (closeVoteDate.getTime() - openVoteDate.getTime()) / (1000 * 60 * 60);
    
    if (closeVoteDate <= openVoteDate) {
      setUpdateMessage('Close vote date must be after open vote date');
      return;
    }

    if (votingDurationHours < 24) {
      setUpdateMessage('Voting duration must be at least 24 hours');
      return;
    }

    if (votingDurationHours > 168) { // 7 days
      setUpdateMessage('Voting duration cannot exceed 7 days');
      return;
    }

    try {
      await updateProposal(selectedProposal.id, {
        ...selectedProposal,
        status: selectedStatusId,
        openvote: utcOpenVote.toISOString(),
        closevote: utcCloseVote.toISOString()
      });
      
      // Refresh proposals
      const updatedProposals = await getProposals();
      setProposals(updatedProposals);
      
      setUpdateMessage('Schedule updated successfully');
    } catch (error) {
      console.error('Error updating schedule:', error);
      setUpdateMessage('Failed to update schedule');
    }
  };

  return (
    <div className="space-y-6">
      {/* Notification Message */}
      {updateMessage && (
        <div className="p-4 bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 rounded-lg">
          {updateMessage}
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex gap-4">
        {['manage', 'snapshots', 'wallets'].map((action) => (
          <button
            key={action}
            onClick={() => setActiveAction(action)}
            className={`flex-1 py-3 text-lg font-medium rounded-xl transition-all duration-200
              ${activeAction === action 
                ? 'bg-gradient-to-r from-primary to-secondary text-white shadow-md' 
                : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
              }`}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </button>
        ))}
      </div>

      {/* Action Forms */}
      <div className="card p-4">
        {!selectedProposal ? (
          <div className="text-center text-gray-500 dark:text-gray-400 py-4">
            <p>Select a proposal from the table below to {activeAction} it</p>
          </div>
        ) : activeAction === 'snapshots' ? (
          <ProposalSnapshots
            proposals={proposals}
            selectedProposalId={selectedProposal?.id ?? null}
            setSelectedProposalId={(id) => setSelectedProposal(proposals.find(p => p.id === id) ?? null)}
            proposalSnapshots={proposalSnapshots}
            setProposalSnapshots={setProposalSnapshots}
            isCreatingSnapshot={isCreatingSnapshot}
            setIsCreatingSnapshot={setIsCreatingSnapshot}
            setUpdateMessage={setUpdateMessage}
          />
        ) : activeAction === 'wallets' ? (
          <ProposalWalletOperations
            proposals={proposals}
            selectedProposal={selectedProposal}
            setUpdateMessage={setUpdateMessage}
          />
        ) : (
          <ProposalActions
            proposals={proposals}
            statuses={statuses}
            selectedProposalId={selectedProposal?.id ?? null}
            setSelectedProposalId={(id) => setSelectedProposal(proposals.find(p => p.id === id) ?? null)}
            selectedStatusId={selectedStatusId}
            setSelectedStatusId={setSelectedStatusId}
            openVoteDate={openVoteDate}
            setOpenVoteDate={setOpenVoteDate}
            closeVoteDate={closeVoteDate}
            setCloseVoteDate={setCloseVoteDate}
            approveRejectChoice={approveRejectChoice}
            setApproveRejectChoice={setApproveRejectChoice}
            handleApproveReject={handleApproveReject}
            handleStatusUpdate={handleStatusUpdate}
            handleSchedule={handleSchedule}
          />
        )}
      </div>

      {/* Table */}
      <div className="card">
        <ManagementProposalsTable
          proposals={proposals}
          statuses={statuses}
          activeAction={activeAction}
          onProposalSelect={handleProposalSelect}
          proposalSnapshots={proposalSnapshots}
        />
      </div>
    </div>
  );
};

export default ProposalsTab; 