import React, { useState, useEffect } from 'react';
import { getElectionTypes, getElectionStatuses, getElectionPositions, createElection, getElections, updateElection } from '../../services/apiService';
import { NewElection, ElectionType, ElectionStatus, ElectionPosition, Election, UpdateElection } from '../../types';
import ElectionForm from './elections/ElectionForm';
import ElectionList from './elections/ElectionList';
import ElectionPositions from './elections/ElectionPositions';
import ElectionCandidates from './elections/ElectionCandidates';

interface ElectionsTabProps {
  updateMessage: string | null;
  setUpdateMessage: (message: string | null) => void;
}

export default function ElectionsTab({ updateMessage, setUpdateMessage }: ElectionsTabProps) {
  const [activeAction, setActiveAction] = useState<string | null>(null);
  const [electionTypes, setElectionTypes] = useState<ElectionType[]>([]);
  const [electionStatuses, setElectionStatuses] = useState<ElectionStatus[]>([]);
  const [electionPositions, setElectionPositions] = useState<ElectionPosition[]>([]);
  const [elections, setElections] = useState<Election[]>([]);
  const [selectedElection, setSelectedElection] = useState<Election | null>(null);
  const [editedElection, setEditedElection] = useState<UpdateElection | null>(null);
  const [newElection, setNewElection] = useState<NewElection>({
    title: '',
    description: '',
    type: 0,
    position: 0,
    status: 0,
    reviewed: false,
    approved: false,
    votesactive: false
  });

  useEffect(() => {
    const fetchElectionData = async () => {
      if (activeAction === 'new' || activeAction === 'review') {
        try {
          const [types, statuses, positions] = await Promise.all([
            getElectionTypes(),
            getElectionStatuses(),
            getElectionPositions()
          ]);
          setElectionTypes(types);
          setElectionStatuses(statuses);
          setElectionPositions(positions);
        } catch (error) {
          console.error('Error fetching election data:', error);
          setUpdateMessage('Failed to load election data');
        }
      }
    };

    fetchElectionData();
  }, [activeAction, setUpdateMessage]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      if (selectedElection) {
        try {
          const [types, statuses, positions] = await Promise.all([
            getElectionTypes(),
            getElectionStatuses(),
            getElectionPositions()
          ]);
          setElectionTypes(types);
          setElectionStatuses(statuses);
          setElectionPositions(positions);
        } catch (error) {
          console.error('Error fetching dropdown data:', error);
          setUpdateMessage('Failed to load dropdown options');
        }
      }
    };

    fetchDropdownData();
  }, [selectedElection, setUpdateMessage]);

  useEffect(() => {
    const fetchElections = async () => {
      if (activeAction === 'review') {
        try {
          const data = await getElections();
          setElections(data);
        } catch (error) {
          console.error('Error fetching elections:', error);
          setUpdateMessage('Failed to load elections');
        }
      }
    };

    fetchElections();
  }, [activeAction, setUpdateMessage]);

  const handleElectionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createElection(newElection);
      setUpdateMessage('Election created successfully');
      // Reset the form
      setNewElection({
        title: '',
        description: '',
        type: 0,
        position: 0,
        status: 0,
        reviewed: false,
        approved: false,
        votesactive: false
      });
      // Reset the action state after a short delay to show the success message
      setTimeout(() => {
        setActiveAction(null);
        setUpdateMessage(null);
      }, 2000);
    } catch (error) {
      console.error('Error creating election:', error);
      setUpdateMessage('Failed to create election');
    }
  };

  const handleElectionSelect = (election: Election) => {
    setSelectedElection(election);
    setEditedElection({
      title: election.title || '',
      description: election.description || '',
      type: election.type,
      position: election.position,
      status: election.status,
      reviewed: Boolean(election.reviewed),
      approved: Boolean(election.approved),
      votesactive: Boolean(election.votesactive),
      openvote: election.openvote || null,
      closevote: election.closevote || null,
      firstcandidate: election.firstcandidate || null,
      secondcandidate: election.secondcandidate || null
    });
  };

  const handleUpdateElection = async () => {
    if (!selectedElection?.id || !editedElection) return;

    try {
      await updateElection(selectedElection.id, editedElection);
      setUpdateMessage('Election updated successfully');
      // Refresh elections list
      const updatedElections = await getElections();
      setElections(updatedElections);
      // Reset selection after a delay
      setTimeout(() => {
        setSelectedElection(null);
        setEditedElection(null);
        setUpdateMessage(null);
      }, 2000);
    } catch (error) {
      console.error('Error updating election:', error);
      setUpdateMessage('Failed to update election');
    }
  };

  const refreshPositions = async () => {
    try {
      const positions = await getElectionPositions();
      setElectionPositions(positions);
    } catch (error) {
      console.error('Error refreshing positions:', error);
      setUpdateMessage('Failed to refresh positions');
    }
  };

  return (
    <div className="space-y-8">
      {/* Notification Message */}
      {updateMessage && (
        <div className="p-4 bg-green-100 text-green-800 rounded text-center">
          {updateMessage}
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
        {['new', 'review', 'positions', 'candidates', 'wallets'].map((action) => (
          <button
            key={action}
            onClick={() => {
              setActiveAction(action);
              setUpdateMessage(null);
            }}
            className={`button-primary capitalize ${
              activeAction === action ? 'ring-2 ring-primary ring-offset-2 dark:ring-offset-gray-900' : ''
            }`}
          >
            {action}
          </button>
        ))}
      </div>

      <div className="card p-6">
        {activeAction === 'new' && (
          <ElectionForm
            newElection={newElection}
            setNewElection={setNewElection}
            electionTypes={electionTypes}
            electionStatuses={electionStatuses}
            electionPositions={electionPositions}
            handleElectionSubmit={handleElectionSubmit}
          />
        )}

        {activeAction === 'review' && (
          <ElectionList
            elections={elections}
            selectedElection={selectedElection}
            editedElection={editedElection}
            setEditedElection={setEditedElection}
            electionTypes={electionTypes}
            electionStatuses={electionStatuses}
            electionPositions={electionPositions}
            handleElectionSelect={handleElectionSelect}
            handleUpdateElection={handleUpdateElection}
            setSelectedElection={setSelectedElection}
          />
        )}

        {activeAction === 'positions' && (
          <ElectionPositions
            positions={electionPositions}
            setUpdateMessage={setUpdateMessage}
            onPositionsChange={refreshPositions}
          />
        )}

        {activeAction === 'candidates' && (
          <ElectionCandidates
            elections={elections}
            setUpdateMessage={setUpdateMessage}
          />
        )}

        {activeAction === 'wallets' && (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Wallets</h3>
            {/* Content for wallets functionality will go here */}
          </div>
        )}

        {!activeAction && (
          <div className="text-center text-gray-600 dark:text-gray-300">
            Select an action above to manage elections
          </div>
        )}
      </div>
    </div>
  );
} 